import React, { useEffect } from "react";
import styles from "../../quemSomos/quemSomos.module.css";
import Header from "../../../components/english/en_Header/en_header";
import Footer from "../../../components/english/en_Footer/en_footer";
import qs_s2_img from "../../../assets/img/qs_s2_img.png";
import qs_s3_img from "../../../assets/img/qs_s3_img.png";

function QuemSomos() {
  useEffect(() => {
    document.title = "Institutional - Nutriz";

    document.addEventListener("scroll", function () {
      const scrollPosition = window.scrollY;
      const s2Img = document.getElementById("qs_s2_img");
      const s3Img = document.getElementById("qs_s3_img");

      // Ajusta a velocidade e direção do movimento
      const speed = 0.1;

      // Inverte o movimento das imagens
      s2Img.style.transform = `translateY(${-scrollPosition * speed}px)`;
      s3Img.style.transform = `translateY(${-scrollPosition * speed}px)`;
    });
  }, []);

  return (
    <main className={styles.main_qs}>
      <Header />
      <section className={styles.backgroundLavoura}>
        <h1 className={styles.h1_animation_qs}>
          Nutriz: A History of Excellence in Frozen Foods
        </h1>
        <a href="#qs_s2" className={styles.svg_seta}>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 32 32"
          >
            <path
              d="M16 23.333c-0.111 0-0.22-0.028-0.319-0.083l-14.667-8c-0.324-0.176-0.443-0.58-0.267-0.904 0.176-0.323 0.584-0.443 0.904-0.267l14.348 7.827 14.349-7.827c0.321-0.176 0.727-0.056 0.904 0.267 0.175 0.324 0.057 0.728-0.267 0.904l-14.667 8c-0.1 0.055-0.209 0.083-0.32 0.083zM16 18c-0.111 0-0.22-0.028-0.319-0.083l-14.667-8c-0.324-0.176-0.443-0.58-0.267-0.904 0.176-0.323 0.584-0.443 0.904-0.267l14.348 7.827 14.349-7.827c0.321-0.176 0.727-0.056 0.904 0.267 0.175 0.324 0.057 0.728-0.267 0.904l-14.667 8c-0.1 0.055-0.209 0.083-0.32 0.083z"
              fill="white"
            ></path>
          </svg>
        </a>
      </section>

      <section className={styles.qs_s2} id="qs_s2">
        <div className={styles.qs_s2_div1}>
          <p className={styles.qs_s2_titulo}>
            The Conscious Choice for Quality Frozen Foods
          </p>
          <p>
            Nutriz has gained a strong presence in Brazil through its innovative
            approach, utilizing advanced technologies that set it apart in the
            market. The company ensures product quality year-round, from seed
            selection to delivery, with waste control and responsible labor.
          </p>
          <p>
            The brand is recognized not only for its processing speed but also
            for freshness and quality surpassing conventional fresh products.
            Offering a wide range of preservative-free frozen foods in 100%
            recyclable packaging, Nutriz provides convenience, health, and
            environmental respect.
          </p>
          <p>
            With a selection of natural ingredients for healthy nutrition, our
            unique freezing process preserves flavor and nutrients.
          </p>
        </div>
        <img src={qs_s2_img} id="qs_s2_img" className={styles.qs_s2_img}></img>
        <img src={qs_s2_img} className={styles.qs_s2_img_mobile}></img>
      </section>

      <section className={styles.qs_s3}>
        <img src={qs_s3_img} id="qs_s3_img" className={styles.qs_s3_img}></img>
        <img src={qs_s3_img} className={styles.qs_s3_img_mobile}></img>
        <div className={styles.qs_s3_div1}>
          <p className={styles.qs_s3_titulo}>
            A Journey of Growth and Innovation
          </p>
          <p>
            Founded in 2003, Nutriz has established itself as a reference in the
            frozen food market. In 2008, it inaugurated its own cold storage
            chamber, enhancing logistics and earning customer trust. In 2011, it
            launched a wholesale product line, followed by a visual packaging
            overhaul in 2012.
          </p>
          <p>
            Over time, Nutriz also created the brands BOM APETITE and MACERATA,
            aimed at assisting the company’s strategic market presence. These
            brands are used for the commercialization of some Nutriz product
            line items and for distributing specific foods in certain regions of
            Brazil.
          </p>
          <p>
            Since its inception, Nutriz has continued to evolve, expand, and
            increasingly offer quality and variety to all its customers.
          </p>
        </div>
      </section>

      <svg
        aria-hidden="true"
        fill="#df231d"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 50"
        preserveAspectRatio="none"
      >
        <path d="M0 40c0 0 200-25 500-25s500 25 500 25V0H0v50z"></path>
      </svg>

      <section className={styles.qs_s4}>
        <div className={styles.div_qs_s4}>
          <h3>Mission</h3>
          <p>
            Produce, market and distribute safe, healthy and tasty food
            efficiently and using high technology
          </p>
        </div>
        <div className={styles.div_qs_s4}>
          <h3>Vision</h3>
          <p>
            To be a reference in frozen foods and vegetables and to make Nutriz
            brands synonymous with quality products throughout Brazil
          </p>
        </div>
        <div className={styles.div_qs_s4}>
          <h3>Values</h3>
          <p>
            Ethics, reputation, transparency, social responsibility and respect
            for the environment
          </p>
        </div>
        <div className={styles.div_qs_s4}>
          <h3>Philosophy</h3>
          <p>
            Discipline, commitment, teamwork, continuous innovation and absolute
            respect for legislation, customers, employees, suppliers and
            partners
          </p>
        </div>
        <div className={styles.div_qs_s4}>
          <h3>Goals</h3>
          <p>Operational and commercial efficiency, profit and growth</p>
        </div>
      </section>

      <section className={styles.qs_s5}>
        <h2 className={styles.qs_s5}>
        Integrated Food
          <span>
            <span className={styles.span_sub_qs_s5}>Quality and Safety</span>
            <svg
              className={styles.svg_s5}
              viewBox="-400 -55 730 60"
              preserveAspectRatio="none"
            >
              <path
                d="m -383.25 -6 c 55.25 -22 130.75 -33.5 293.25 -38 c 54.5 -0.5 195 -2.5 401 15"
                stroke="#df231d"
                pathLength="1"
                strokeWidth="14"
                fill="none"
              ></path>
            </svg>
          </span>
          Policy
        </h2>

        <p>
        Nutriz Indústria e Comércio de Alimentos Ltda, in its frozen vegetable production activities, works through the commitment, involvement and development of its employees for the continuous improvement of the quality and food safety management process. Through its various communication channels, it ensures a good relationship with its stakeholders. It maintains the effectiveness and efficiency of production processes in order to exceed consumer expectations and meet legal and customer requirements, thus maximizing the company's value.
        </p>
      </section>

      <section className={styles.qs_s6}>
        <h2>
        Quality Food, Customer Satisfaction, and Socio-Environmental Responsibility
        </h2>
        <p>
        Nutriz seeks customer satisfaction with healthy products, improves processes, integrates suppliers, promotes human growth, respects the environment, and contributes to Brazil’s social and economic development through quality food.


        </p>
      </section>

      <section className={styles.qs_s7}>
        <h2>
        Experience now and discover the quality and flavor of Nutriz’s products!

        </h2>
        <a href="/en/products" className={styles.link_conheca_prod}>
          <span className={`${styles.seta} bi bi-arrow-right`}></span>
          <span className={`${styles.seta2} bi bi-dash-lg`}></span>
          Explore our products
        </a>
      </section>

      <Footer />
    </main>
  );
}

export default QuemSomos;
