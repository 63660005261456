import React from "react";
import styles from "../../Footer/footer.module.css";
import logo_branca from "../../../assets/img/Logo-negativo-white.png";
import logo22000 from "../../../assets/img/DNV_FSSC-22000.png";

const Footer = () => {
  return (
    <footer className={styles.footer_home}>
      <div className={styles.footer_div}>
        <div className={styles.footer_div_imagens}>
          <img src={logo_branca} className={styles.logo_branca}></img>
          <img src={logo22000} className={styles.logo22000}></img>
        </div>
        <div className={styles.div_mapa_inst}>
          <div>
            <p className={styles.titulo_footer}>Mapa Del Sitio</p>
            <a href="/es" className={styles.link_footer}>
              <span className={`${styles.seta} bi bi-arrow-right`}></span>
              <span className={`${styles.seta2} bi bi-dash-lg`}></span>
              Home
            </a>
            <a href="/es/quienes-somos" className={styles.link_footer}>
              <span className={`${styles.seta} bi bi-arrow-right`}></span>
              <span className={`${styles.seta2} bi bi-dash-lg`}></span>
              Quienes Somos
            </a>
            <a href="/es/productos" className={styles.link_footer}>
              <span className={`${styles.seta} bi bi-arrow-right`}></span>
              <span className={`${styles.seta2} bi bi-dash-lg`}></span>
              Productos
            </a>
            <a href="/es/#secao6" className={styles.link_footer}>
              <span className={`${styles.seta} bi bi-arrow-right`}></span>
              <span className={`${styles.seta2} bi bi-dash-lg`}></span>
              Recipes
            </a>
          </div>
          <div>
            <p className={styles.titulo_footer}>Institucional</p>
            <a href="/es/politica-de-privacidad" className={styles.link_footer}>
              <span className={`${styles.seta} bi bi-arrow-right`}></span>
              <span className={`${styles.seta2} bi bi-dash-lg`}></span>
              Política de Privacidad
            </a>
            <a href="/es/trabaja-con-nosotros" className={styles.link_footer}>
              <span className={`${styles.seta} bi bi-arrow-right`}></span>
              <span className={`${styles.seta2} bi bi-dash-lg`}></span>
              Trabaja con Nosotros
            </a>
            <a href="/es/contacto" className={styles.link_footer}>
              <span className={`${styles.seta} bi bi-arrow-right`}></span>
              <span className={`${styles.seta2} bi bi-dash-lg`}></span>
              SAC
            </a>
          </div>
        </div>
      </div>
      <p className={styles.p_footer}>
        <i className={`bi bi-c-circle`}></i>2024 Nutriz
      </p>
    </footer>
  );
};

export default Footer;
