import {
  BrowserRouter as Router,
  Routes as RouterRoutes,
  Route,
} from "react-router-dom";
import Home from "./pages/home/home";
import Erro from "./pages/404/404";
import QuemSomos from "./pages/quemSomos/quemSomos"
import Certificacoes from "./pages/certificacoes/certificacoes"
import Produtos from "./pages/produtos/produtos"
import Produto from "./pages/produtoIndividual/produto"
import TrabalheConosco from "./pages/trabalheConosco/trabalheConosco"
import PoliticaDePrivacidade from "./pages/politicaDePrivacidade/PoliticaDePrivacidade"
import Contato from "./pages/contato/contato"
import LinkTree from "./pages/linktree/linktree"

import EnHome from "./pages/english/en_home/en_home";
import EnQuemSomos from "./pages/english/en_quemSomos/en_quemSomos";
import EnCertificacoes from "./pages/english/en_certificacoes/en_certificacoes";
import EnProdutos from "./pages/english/en_produtos/en_produtos";
import EnProduto from "./pages/english/en_produtoIndividual/en_produto";
import EnTrabalheConosco from "./pages/english/en_trabalheConosco/en_trabalheConosco";
import EnContato from "./pages/english/en_contato/en_contato";
import EnPoliticaDePrivacidade from "./pages/english/en_politicaDePrivacidade/en_PoliticaDePrivacidade";

import EsHome from "./pages/espanhol/es_home/es_home";
import EsQuemSomos from "./pages/espanhol/es_quemSomos/es_quemSomos";
import EsCertificacoes from "./pages/espanhol/es_certificacoes/es_certificacoes";
import EsProdutos from "./pages/espanhol/es_produtos/es_produtos";
import EsProduto from "./pages/espanhol/es_produtoIndividual/es_produto";
import EsTrabalheConosco from "./pages/espanhol/es_trabalheConosco/es_trabalheConosco";
import EsContato from "./pages/espanhol/es_contato/es_contato";
import EsPoliticaDePrivacidade from "./pages/espanhol/es_politicaDePrivacidade/es_PoliticaDePrivacidade";

import Agendar from "./pages/agendamentos/agendar";
import Restaurantes from "./pages/restaurantes/rest";

const Routes = () => {
  return (
    <Router>
      <RouterRoutes>
        <Route path="/" element={<Home />} />
        <Route path="/institucional" element={<QuemSomos />} />
        <Route path="/certificacoes" element={<Certificacoes />} />
        <Route path="/produtos" element={<Produtos />} />
        <Route path="/produto/:id" element={<Produto />} />
        <Route path="/trabalhe-conosco" element={<TrabalheConosco />} />
        <Route path="/contato" element={<Contato />} />
        <Route path="/politica-de-privacidade" element={<PoliticaDePrivacidade />} />
        <Route path="/linktree" element={<LinkTree />} />

        <Route path="/en" element={<EnHome />} />
        <Route path="/en/institutional" element={<EnQuemSomos />} />
        <Route path="/en/certifications" element={<EnCertificacoes />} />
        <Route path="/en/products" element={<EnProdutos />} />
        <Route path="/en/product/:id" element={<EnProduto />} />
        <Route path="/en/join-us" element={<EnTrabalheConosco />} />
        <Route path="/en/contact-us" element={<EnContato />} />
        <Route path="/en/privacy-policy" element={<EnPoliticaDePrivacidade />} />

        <Route path="/es" element={<EsHome />} />
        <Route path="/es/quienes-somos" element={<EsQuemSomos />} />
        <Route path="/es/certificaciones" element={<EsCertificacoes />} />
        <Route path="/es/productos" element={<EsProdutos />} />
        <Route path="/es/producto/:id" element={<EsProduto />} />
        <Route path="/es/trabaja-con-nosotros" element={<EsTrabalheConosco />} />
        <Route path="/es/contacto" element={<EsContato />} />
        <Route path="/es/politica-de-privacidad" element={<EsPoliticaDePrivacidade />} />

        <Route path="/agendar" element={<Agendar />} />
        <Route path="/lp-forms-restaurante" element={<Restaurantes />} />

        <Route path="*" element={<Erro />} />
      </RouterRoutes>
    </Router>
  );
};

export default Routes;
