import React, { useEffect, useState } from "react";
import styles from "../../contato/contato.module.css";
import Header from "../../../components/espanhol/es_Header/es_header";
import Footer from "../../../components/espanhol/es_Footer/es_footer";
import emailjs from "@emailjs/browser";

function Contato() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [assunto, setAssunto] = useState("");
  const [mensagem, setMensagem] = useState("");

  function sendEmail(e) {
    e.preventDefault();

    const templateParams = {
      from_name: name,
      email: email,
      empresa: empresa,
      assunto: assunto,
      message: mensagem,
    };

    emailjs
      .send(
        "service_1d99arb",
        "template_3pc9fdr",
        templateParams,
        "muoGg7jBcvD9hqCRI"
      )
      .then(
        (response) => {
          console.log("Email enviado", response.status, response.text);
          setName("");
          setEmail("");
          setEmpresa("");
          setAssunto("");
          setMensagem("");
        },
        (err) => {
          console.log("Erro: ", err);
        }
      );
    alert(
      "E-mail enviado exitosamente! Agradecemos su contacto y nos comunicaremos con usted pronto."
    );
  }
  useEffect(() => {
    document.title = "Contacto - Nutriz";
  }, []);

  return (
    <main className={styles.main_ctt}>
      <Header />
      <section className={styles.ctt_s1}>
        <h1 className={styles.titulo_ctt}>¿Vamos a hablar?</h1>
        <p className={styles.titulo_ctt}>Contáctenos.</p>
      </section>

      <section className={styles.ctt_s2}>
        <div className={styles.div_endereco}>
          <div className={styles.ctt_tel_email}>
            <a href="tel:01121716666">
              <span className={`bi bi-telephone`}></span>
              <span>(11) 2171 - 6666</span>
            </a>
            <a href="sac@nutriz.com.br">
              <span className={`bi bi-envelope`}></span>
              <span>sac@nutriz.com.br</span>
            </a>
          </div>
          <div className={styles.div_ctt_endereco}>
            <p className={styles.ctt_ind_escr}>Industria</p>
            <p className={styles.rs_sp}>Rio Grande do Sul</p>
            <a
              href="https://goo.gl/maps/JnyJVeY3d8zK7nJW9"
              target="_blanck"
              className={styles.ctt_endereco_maps}
            >
              Rua Buarque de Macedo, n° 965 - Bairro: Gentil. Lagoa Vermelha -
              RS / Brasil <br></br>CEP: 95300-000
            </a>
          </div>
          <div className={styles.div_ctt_endereco}>
            <p className={styles.ctt_ind_escr}>Escritorio</p>
            <p className={styles.rs_sp}>São Paulo</p>
            <a
              href="https://goo.gl/maps/kSJdjja9as5fBL8S8"
              target="_blanck"
              className={styles.ctt_endereco_maps}
            >
              Av. dos Autonomistas, n° 896, Sala 2804, 28° Andar. Torre
              Santorini, Vila Yara, Osasco - SP / Brasil <br></br>CEP: 06020-010
            </a>
          </div>
        </div>

        <div>
          <form className={styles.ctt_form} onSubmit={sendEmail}>
            <div>
              <label htmlFor="nome_contato">Su nombre</label>
              <input
                type="text"
                id="nome_contato"
                required
                onChange={(e) => setName(e.target.value)}
                value={name}
              ></input>
            </div>
            <div>
              <label htmlFor="email_contato">Su e-mail</label>
              <input
                type="email"
                id="email_contato"
                required
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              ></input>
            </div>
            <div>
              <label htmlFor="empresa_contato">Nombre de la empresa</label>
              <input
                type="text"
                id="empresa_contato"
                onChange={(e) => setEmpresa(e.target.value)}
                value={empresa}
              ></input>
            </div>
            <div>
              <label htmlFor="assunto_contato">Sujeto</label>
              <input
                type="text"
                id="assunto_contato"
                onChange={(e) => setAssunto(e.target.value)}
                value={assunto}
              ></input>
            </div>
            <div>
              <label htmlFor="msg_contato">Su mensaje</label>
              <textarea
                type="text"
                id="msg_contato"
                className={styles.msg_contato}
                onChange={(e) => setMensagem(e.target.value)}
                value={mensagem}
              ></textarea>
            </div>
            <button className={styles.ctt_botao}>MANDAR</button>
          </form>
        </div>
      </section>
      <Footer />
    </main>
  );
}

export default Contato;
