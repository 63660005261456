import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import styles from "./en_home.module.css";
import imagem_brocolis_secao2 from "../../../assets/img/imagem_brocolis_secao2.png";
import banner_s5_1 from "../../../assets/img/banner_s5_1.png";
import banner_s5_2 from "../../../assets/img/banner_s5_2.png";
import banner_s5_3 from "../../../assets/img/banner_s5_3.png";
import banner_s5_4 from "../../../assets/img/banner_s5_4.png";
import BackgroundVideo from "../../../components/english/en_video/en_video";
import Header from "../../../components/english/en_Header/en_header";
import Footer from "../../../components/english/en_Footer/en_footer";
import Popup from "../../../components/english/en_popup/en_popup";


function Home() {

  let nav = "1"
  if (window.innerWidth < 500) {
    nav = "2";
  }

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPopupOpen(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    AOS.init({ duration: 1000 });
    document.title = "Nutriz Alimentos Congelados";

    const hash = window.location.hash;
    if (hash === "/en/#secao6") {
      window.location.href = "https://nutriz.com.br/en/#secao6";
    }

    // efeito de rolagem da imagem
    const handleScroll = () => {
      const section = document.querySelector(`.${styles.secao4}`);
      if (section) {
        const scrollPosition = window.pageYOffset;
        const windowHeight = window.innerHeight;
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        if (
          scrollPosition + windowHeight > sectionTop &&
          scrollPosition < sectionTop + sectionHeight
        ) {
          const offset =
            (scrollPosition + windowHeight - sectionTop) /
            (3 * (windowHeight + sectionHeight));

          section.style.setProperty("--bg-position", `${80 - offset * 80}%`);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <main className={styles.main_home}>
      <Header />
      <BackgroundVideo web={nav}/>
      {/* {isPopupOpen && <Popup handleClose={closePopup} />} */}

      <section className={styles.secao2}>
        <div className={styles.div_textos_s2}>
          <p
            className={styles.titulo_s2}
            data-aos="fade-up"
            data-aos-duration="500"
          >
            Nutriz: Flavor and
          </p>
          <p
            className={styles.titulo_s2}
            data-aos="fade-up"
            data-aos-duration="750"
          >
            Freshness at
          </p>
          <p
            className={styles.titulo_s2}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Your Table
          </p>

          <p>
            Nutriz is present throughout Brazil, adopting an innovative approach
            and utilizing modern equipment to ensure quality at every stage,
            from seed selection to customer delivery.
          </p>
          <p>
            Nutriz’s products are environmentally friendly, preserving the
            nutritional characteristics, taste, and texture of the food.
          </p>
          <p>
            The company is recognized in the market as a frozen food industry
            leader, distinguished by its commitment and seriousness.
          </p>
          <a href="/en/institutional" className={styles.botao_saibamais_s2}>
            LEARN MORE
          </a>
        </div>
        <img src={imagem_brocolis_secao2}></img>
      </section>

      <section className={styles.secao3}>
        <h1>Discover the key differentiators of Nutriz’s foods:</h1>
        <div className={styles.div_s3}>
          <div className={styles.div1_s3}>
            <p className={styles.titulo_div1_s3}>
              100% natural and additive-free
            </p>
            <p className={styles.p_div1_s3}>
              Selected, cleaned, peeled, chopped, and individually frozen
              without artificial colors or preservatives to preserve
              authenticity and food quality.
            </p>
          </div>
          <div className={styles.div2_s3}>
            <p className={styles.titulo_div2_s3}>Convenience and savings</p>
            <p className={styles.p_div2_s3}>
              Reduced kitchen work, no need to thaw before preparation, and the
              option to return the remaining portion to the freezer, preventing
              waste.
            </p>
          </div>
          <div className={styles.div3_s3}>
            <p className={styles.titulo_div3_s3}>
              Quick preparation, guaranteed quality
            </p>
            <p className={styles.p_div3_s3}>
              Nutriz’s foods offer fast preparation while ensuring nutritional
              and microbiological quality.
            </p>
          </div>
        </div>
      </section>

      <section className={styles.secao4}>
        <p>
          The
          <span className={styles.span_s4}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              focusable="false"
              viewBox="0 0 12 12"
            >
              <path stroke="currentColor" strokeLinecap="round" d="M3 6.5h6" />
            </svg>
            finest
          </span>
          frozen meals!
        </p>
      </section>

      <section className={styles.secao5}>
        <a href="/en/products" data-aos="fade-left" data-aos-delay="50">
          <div className={styles.img_container}>
            <img src={banner_s5_1} alt="Line Institutional" />
          </div>
          <p>Line</p>
          <p className={styles.s2_descr}>Institutional</p>
        </a>
        <a href="/en/products" data-aos="fade-left" data-aos-delay="150">
          <div className={styles.img_container}>
            <img src={banner_s5_2} alt="Line Wholesale" />
          </div>
          <p>Line</p>
          <p className={styles.s2_descr}>Wholesale</p>
        </a>
        <a href="/en/products" data-aos="fade-left" data-aos-delay="250">
          <div className={styles.img_container}>
            <img src={banner_s5_3} alt="Line Retail" />
          </div>
          <p>Line</p>
          <p className={styles.s2_descr}>Retail</p>
        </a>
        <a href="/en/products" data-aos="fade-left" data-aos-delay="350">
          <div className={styles.img_container}>
            <img src={banner_s5_4} alt="Line Bom Apetite" />
          </div>
          <p>Line</p>
          <p id="secao6" className={styles.s2_descr}>
            Bom Apetite
          </p>
        </a>
      </section>

      <section className={styles.secao6}>
        <p data-aos="fade-up" data-aos-delay="30">
        Get inspired with <br />
          <span className={styles.span_receitas}>
            <svg
              className={styles.svg_receitas}
              viewBox="-400 -55 730 60"
              preserveAspectRatio="none"
            >
              <path
                d="m -383.25 -6 c 55.25 -22 130.75 -33.5 293.25 -38 c 54.5 -0.5 195 -2.5 401 15"
                stroke="#007943"
                pathLength="1"
                strokeWidth="20"
                fill="none"
              ></path>
            </svg>
            delicious recipes
          </span>{" "}
          to prepare with your Nutriz foods!
        </p>
        <a href="https://www.instagram.com/stories/highlights/18240165124258007">CHECK!</a>
      </section>

      <Footer />
    </main>
  );
}

export default Home;
