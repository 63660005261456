import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // Importa o hook useLocation
import styles from "../../Header/header.module.css";
import img_logo from "../../../assets/img/logo.png";
import instagram from "../../../assets/img/instagram.png";
import facebook from "../../../assets/img/facebook.png";
import linkedin from "../../../assets/img/linkedin.png";
import br from "../../../assets/img/br.png";
import us from "../../../assets/img/us.png";
import es from "../../../assets/img/es.png";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isDivVisible, setIsDivVisible] = useState(false);
  const [isSvgXVisible, setIsSvgXVisible] = useState(false); // Estado para controlar a visibilidade dos SVGs
  const location = useLocation(); // Obtém a URL atual

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDivVisibility = () => {
    setIsDivVisible(!isDivVisible);
    setIsSvgXVisible(!isSvgXVisible); // Alterna a visibilidade dos SVGs
  };

  return (
    <main>
      <section className={styles.cabecalho}>
        <img
          src={img_logo}
          className={`${styles.logo} ${scrolled ? styles.logoScrolled : ""}`}
          onClick={() => {
            window.location.href = "/es";
          }}
        />
        <button
          onClick={toggleDivVisibility}
          className={styles.icone_cabecalho_celular}
        >
          <svg
            className={`${styles.svg_icone} ${
              isSvgXVisible ? styles.hidden : ""
            }`}
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="#007943"
            viewBox="0 0 16 16"
          >
            <path d="M0 3.5A.5.5 0 0 1 .5 3h15a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5zm0 5A.5.5 0 0 1 .5 8h15a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5" />
          </svg>
          <svg
            className={`${styles.svg_x} ${isSvgXVisible ? "" : styles.hidden}`}
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="#007943"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
          </svg>
        </button>
        <div className={`${styles.div_cabecalho}`}>
          <a
            href="/es"
            className={`${styles.links_cab} ${
              location.pathname === "/es" ? styles.active : ""
            }`}
          >
            Home
          </a>
          <a
            href="/es/quienes-somos"
            className={`${styles.links_cab} ${
              location.pathname === "/es/quienes-somos" ? styles.active : ""
            }`}
          >
            Quienes Somos
          </a>
          <a
            href="/es/certificaciones"
            className={`${styles.links_cab} ${
              location.pathname === "/es/certificaciones" ? styles.active : ""
            }`}
          >
            Certificaciones
          </a>
          <a
            href="/es/productos"
            className={`${styles.links_cab} ${
              location.pathname === "/es/productos" ? styles.active : ""
            }`}
          >
            Productos
          </a>
          <a
            href="/es/#secao6"
            className={`${styles.links_cab} ${
              location.pathname === "/es/#secao6" ? styles.active : ""
            }`}
          >
            Recetas
          </a>
          <a
            href="/es/trabaja-con-nosotros"
            className={`${styles.links_cab} ${
              location.pathname === "/es/trabaja-con-nosotros"
                ? styles.active
                : ""
            }`}
          >
            Trabaja con Nosotros
          </a>
          <a
            href="/es/contacto"
            className={`${styles.botao_contato} ${
              location.pathname === "/es/contacto" ? styles.active : ""
            }`}
          >
            Contacto
          </a>
          <div className={styles.div_botoes_cabecalho}>
            <a href="https://www.instagram.com/nutrizalimentos/">
              <img src={instagram} />
            </a>
            <a href="https://www.facebook.com/nutrizalimentos">
              <img src={facebook} />
            </a>
            <a href="https://www.linkedin.com/company/nutriz-industria-ltda/">
              <img src={linkedin} />
            </a>
            <div className={styles.div_linguagens}>
              <a
                href="/"
                className={`${styles.links_cab} ${
                  !location.pathname.includes("/en") &&
                  !location.pathname.includes("/es")
                    ? styles.active
                    : ""
                }`}
              >
                <img src={br} className={styles.link_idioma} />
              </a>
              <a
                href="/en"
                className={`${styles.links_cab} ${
                  location.pathname.includes("/en") ? styles.active : ""
                }`}
              >
                <img src={us} className={styles.link_idioma} />
              </a>
              <a
                href="/es"
                className={`${styles.links_cab} ${
                  location.pathname.includes("/es") ? styles.active : ""
                }`}
              >
                <img src={es} className={styles.link_idioma} />
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* layout diferente para celular */}
      <div
        className={`${styles.div_cabecalho_celular}`}
        style={{ display: isDivVisible ? "flex" : "none" }}
      >
        <a
          href="/es"
          className={`${styles.links_cab} ${
            location.pathname === "/es" ? styles.active : ""
          }`}
        >
          Home
        </a>
        <a
          href="/es/quienes-somos"
          className={`${styles.links_cab} ${
            location.pathname === "/es/quienes-somos" ? styles.active : ""
          }`}
        >
          Quienes Somos
        </a>
        <a
          href="/es/certificaciones"
          className={`${styles.links_cab} ${
            location.pathname === "/es/certificaciones" ? styles.active : ""
          }`}
        >
          Certificaciones
        </a>
        <a
          href="/es/productos"
          className={`${styles.links_cab} ${
            location.pathname === "/es/productos" ? styles.active : ""
          }`}
        >
          Productos
        </a>
        <a
          href="/es/#secao6"
          className={`${styles.links_cab} ${
            location.pathname === "/es/#secao6" ? styles.active : ""
          }`}
        >
          Recetas
        </a>
        <a
          href="/es/trabaja-con-nosotros"
          className={`${styles.links_cab} ${
            location.pathname === "/es/trabaja-con-nosotros"
              ? styles.active
              : ""
          }`}
        >
          Trabaja con Nosotros
        </a>
        <a
          href="/es/contacto"
          className={`${styles.botao_contato} ${
            location.pathname === "/es/contacto" ? styles.active : ""
          }`}
        >
          Contacto
        </a>
        <div className={styles.div_botoes_cabecalho}>
          <a href="https://www.instagram.com/nutrizalimentos/">
            <img src={instagram} />
          </a>
          <a href="https://www.facebook.com/nutrizalimentos">
            <img src={facebook} />
          </a>
          <a href="https://www.linkedin.com/company/nutriz-industria-ltda/">
            <img src={linkedin} />
          </a>

          <a
            href="/"
            className={`${styles.links_cab} ${
              !location.pathname.includes("/en") &&
              !location.pathname.includes("/es")
                ? styles.active
                : ""
            }`}
          >
            <img src={br} className={styles.link_idioma} />
          </a>
          <a
            href="/en"
            className={`${styles.links_cab} ${
              location.pathname.includes("/en") ? styles.active : ""
            }`}
          >
            <img src={us} className={styles.link_idioma} />
          </a>
          <a
            href="/es"
            className={`${styles.links_cab} ${
              location.pathname.includes("/es") ? styles.active : ""
            }`}
          >
            <img src={es} className={styles.link_idioma} />
          </a>
        </div>
      </div>
    </main>
  );
};

export default Header;
