import React, { useEffect, useState } from "react";
import styles from "./rest.module.css";
import logo_branca from "../../assets/img/Logo-negativo-white.png";
import produtos from "../../assets/img/produtos.png";
import prato from "../../assets/img/pratos3.png";
import white22000 from "../../assets/img/22000_white.png";
import broc1 from "../../assets/img/broc1.png";
import broc2 from "../../assets/img/broc2.png";
import cenoura from "../../assets/img/cenoura.png";
import background_s7 from "../../assets/img/background_s7.png";
import footerFacebook from "../../assets/img/footerFacebook.png";
import footerInstagram from "../../assets/img/footerInstagram.png";
import footerLinkedin from "../../assets/img/footerLinkedin.png";
import AOS from "aos";
import "aos/dist/aos.css";
import emailjs from "@emailjs/browser";


function Restaurantes() {
  const [name, setName] = useState("");
  const [telefone, setTelefone] = useState("");
  const [restaurante, setRestaurante] = useState("");
  const [segmento, setSegmento] = useState("");
  const [email, setEmail] = useState("");
  const [cidade, setCidade] = useState("");

  useEffect(() => {
    AOS.init({ duration: 1000 });
    document.title = "Formulário | Nutriz";
  }, []);

  function sendEmail(e) {
    e.preventDefault();

    const templateParams = {
      from_name: name,
      telefone: telefone,
      restaurante: restaurante,
      segmento: segmento,
      email: email,
      cidade: cidade,
    };

    emailjs
      .send(
        "service_mt2hdc4",
        "template_intfo3y",
        templateParams,
        "5ANtNDnjjfsqwXtQj"
      )
      .then(
        (response) => {
          console.log("Email enviado", response.status, response.text);
          setName("");
          setTelefone("");
          setRestaurante("");
          setSegmento("");
          setEmail("");
          setCidade("");
        },
        (err) => {
          console.log("Erro: ", err);
        }
      );
    alert(
      "E-mail enviado com sucesso! Agradecemos o seu contato e retornaremos em breve."
    );
  }

  //formatação telefone:
  const handlePhoneChange = (e) => {
    let telefone = e.target.value;
    telefone = telefone.replace(/\D/g, "");
    if (telefone.length > 10) {
      telefone = telefone.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
    } else if (telefone.length > 5) {
      telefone = telefone.replace(/^(\d{2})(\d{4})(\d{4})$/, "($1) $2-$3");
    } else if (telefone.length > 2) {
      telefone = telefone.replace(/^(\d{2})(\d)/, "($1) $2");
    }
    telefone = telefone.substring(0, 15);
    e.target.value = telefone;
  };

  return (
    <main>
      <header className={styles.header}>
        <img src={logo_branca}></img>
      </header>

      <section className={styles.banner_form}>
        <div className={styles.div_banner}>
          <h1>
            Vegetais congelados <b>direto do campo</b> para a mesa do seu
            negócio!
          </h1>
          <p className={styles.p_desktop}>
            Preencha o formulário <i class="bi bi-arrow-right-short"></i>
          </p>
          <p className={styles.p_mobile}>Preencha o formulário abaixo</p>
        </div>

        <form className={styles.form} id="form" onSubmit={sendEmail}>
          <div>
            <label htmlFor="nome">Nome do Contato*</label>
            <input
              type="text"
              id="nome"
              onChange={(e) => setName(e.target.value)}
              value={name}
              required
            ></input>
          </div>
          <div>
            <label htmlFor="ctt">Telefone do Contato*</label>
            <input
              type="text"
              id="ctt"
              required
              value={telefone}
              onChange={(e) => {
                handlePhoneChange(e);
                setTelefone(e.target.value);
              }}
            ></input>
          </div>
          <div>
            <label htmlFor="rest">Nome do restaurante ou empresa*</label>
            <input
              type="text"
              id="rest"
              value={restaurante}
              required
              onChange={(e) => setRestaurante(e.target.value)}
            ></input>
          </div>
          <div>
            <label htmlFor="segm">Segmento</label>
            <select
              value={segmento}
              onChange={(e) => setSegmento(e.target.value)}
            >
              <option disabled value="">
                Selecione o Segmento
              </option>
              <option value="Bar">Bar</option>
              <option value="Buffet">Buffet</option>
              <option value="Churrascarias">Churrascarias</option>
              <option value="Cozinhas Industriais">Cozinhas Industriais</option>
              <option value="Delivery">Delivery</option>
              <option value="Hospitais">Hospitais</option>
              <option value="Hotéis">Hotéis</option>
              <option value="Lanchonetes">Lanchonetes</option>
              <option value="Marmitaria">Marmitaria</option>
              <option value="Mini Mercado">Mini Mercado</option>
              <option value="Oriental">Oriental</option>
              <option value="Padarias">Padarias</option>
              <option value="Pastelaria">Pastelaria</option>
              <option value="Pizzarias">Pizzarias</option>
              <option value="Restaurante a La Carte">
                Restaurante a La Carte
              </option>
              <option value="Restaurante KG">Restaurante KG</option>
              <option value="Restaurante Vegano">Restaurante Vegano</option>
              <option value="Rotisseria">Rotisseria</option>
              <option value="Outros">Outros</option>
            </select>
          </div>
          <div>
            <label htmlFor="email">E-mail</label>
            <input
              type="mail"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></input>
          </div>
          <div>
            <label htmlFor="cidade">Cidade</label>
            <input
              type="text"
              id="cidade"
              value={cidade}
              onChange={(e) => setCidade(e.target.value)}
            ></input>
          </div>
          <button className={styles.btn_form}>QUERO SABER MAIS!</button>
        </form>
      </section>

      <section className={styles.section_produtos}>
        <img src={produtos} data-aos="fade-up" data-aos-duration="500"></img>
      </section>

      <section className={styles.section3}>
        <div className={styles.div_s3}>
          <p data-aos="fade-left" data-aos-delay="50">
            A Nutriz é uma escolha consciente para
            <b> alimentos congelados de qualidade.</b>
          </p>
          <p data-aos="fade-left" data-aos-delay="50">
            Utilizamos tecnologia avançada para garantir frescor, sabor e
            segurança alimentar em cada produto. Com mais de 20 anos no mercado,
            somos reconhecidos pelo nosso compromisso com a
            <b>sustentabilidade e a saúde.</b>
          </p>
          <a
            href="#form"
            className={styles.a_s3}
            data-aos="fade-left"
            data-aos-delay="50"
          >
            CLIQUE E FAÇA O SEU ORÇAMENTO
          </a>
        </div>
        <img src={prato} className={styles.img_s3}></img>
      </section>
      <section className={styles.section4}>
        <div className={styles.div_s4}>
          <div className={styles.div2_s4}>
            <div className={styles.container_s4}>
              <h3>PRATICIDADE</h3>
              <p>
                Vegetais congelados já vêm lavados, cortados e prontos para uso,
                economizando tempo na preparação. Do freezer direto para a
                panela.
              </p>
            </div>
            <div className={styles.container_s4}>
              <h3>ECONOMIA</h3>
              <p>
                Porcionamneto fácil e sem necessidade de preparar grandes
                quantidades de uma só vez, o que reduz desperdícios. Sem geração
                de resíduos para o preparo.
              </p>
            </div>
          </div>
          <div className={styles.div2_s4}>
            <div className={styles.container_s4}>
              <h3>QUALIDADE</h3>
              <p>
                O processo de ultracongelamento mantém os nutrientes, sabor e
                textura dos vegetais.
              </p>
            </div>
            <div className={styles.container_s4}>
              <h3>CUSTO BENEFÍCIO</h3>
              <p>
                Valores mais estáveis em comparação com vegetais frescos, cujos
                preços podem variar conforme a estação.
              </p>
            </div>
          </div>
        </div>
        <a href="#form" className={styles.a_s4}>
          PREENCHA E FAÇA O SEU ORÇAMENTO!
        </a>
      </section>

      <section className={styles.section5}>
        <img
          src={broc1}
          className={styles.broc1}
          data-aos="fade-up"
          data-aos-duration="500"
        ></img>

        <img
          src={broc2}
          className={styles.broc2}
          data-aos="fade-up"
          data-aos-duration="500"
        ></img>

        <img
          src={white22000}
          className={styles.img_s5_none}
          data-aos="fade-left"
          data-aos-delay="50"
        ></img>
        <h2>CERTIFICAÇÃO DE QUALIDADE</h2>
        <div>
          <img
            src={white22000}
            className={styles.img_s5}
            data-aos="fade-left"
            data-aos-delay="50"
          ></img>
          <p>
            A Nutriz carrega em seu nome o princípio do seu trabalho: Nutrir.
            Mas a nossa missão vai muito além da nutrição e, partindo dessa
            premissa, nos sentimos muito honrados em sermos uma empresa
            produtora de vegetais congelados certificada FSSC 22.000{" "}
            <i>(Food Safety System Certification 22000)</i>, esquema de
            certificação de reconhecimento internacional que demonstra o
            compromisso na Nutriz com a segurança e a qualidade dos alimentos,
            legalidade e melhoria contínua dos seus processos.
          </p>
        </div>
      </section>

      <section className={styles.section6}>
        <h2>
          Facilite o <span>dia a dia</span> do seu restaurante
        </h2>
        <p>
          Experiemente a <b>praticidade e a qualidade</b> dos vegetais
          congelados Nutriz.
        </p>
        <p>Entre em contato e saiba como adquirir nossos produtos!</p>
        <a href="#form" className={styles.a_s6}>
          PREENCHA O FORMULÁRIO E ENTRAREMOS EM CONTATO!
        </a>
      </section>

      <section className={styles.section7}>
        <img
          src={cenoura}
          className={styles.cenoura}
          data-aos="fade-up"
          data-aos-duration="500"
        ></img>
        <img src={background_s7}></img>
      </section>

      <footer className={styles.footer}>
        <img src={logo_branca} className={styles.logo_branca}></img>
        <div>
          <a href="https://www.instagram.com/nutrizalimentos/" target="_blanck">
            <img src={footerInstagram}></img>
          </a>
          <a href="https://www.facebook.com/nutrizalimentos" target="_blanck">
            <img src={footerFacebook}></img>
          </a>
          <a
            href="https://www.linkedin.com/company/nutriz-industria-ltda/"
            target="_blanck"
          >
            <img src={footerLinkedin}></img>
          </a>
        </div>
      </footer>
    </main>
  );
}

export default Restaurantes;
