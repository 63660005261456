import React from "react";
import styles from "../../Footer/footer.module.css";
import logo_branca from "../../../assets/img/Logo-negativo-white.png";
import logo22000 from "../../../assets/img/DNV_FSSC-22000.png";

const Footer = () => {
  return (
    <footer className={styles.footer_home}>
      <div className={styles.footer_div}>
        <div className={styles.footer_div_imagens}>
          <img src={logo_branca} className={styles.logo_branca}></img>
          <img src={logo22000} className={styles.logo22000}></img>
        </div>
        <div className={styles.div_mapa_inst}>
          <div>
            <p className={styles.titulo_footer}>Site Map</p>
            <a href="/en" className={styles.link_footer}>
              <span className={`${styles.seta} bi bi-arrow-right`}></span>
              <span className={`${styles.seta2} bi bi-dash-lg`}></span>
              Home
            </a>
            <a href="/en/institutional" className={styles.link_footer}>
              <span className={`${styles.seta} bi bi-arrow-right`}></span>
              <span className={`${styles.seta2} bi bi-dash-lg`}></span>
              Institutional
            </a>
            <a href="/en/products" className={styles.link_footer}>
              <span className={`${styles.seta} bi bi-arrow-right`}></span>
              <span className={`${styles.seta2} bi bi-dash-lg`}></span>
              Products
            </a>
            <a href="/en/#secao6" className={styles.link_footer}>
              <span className={`${styles.seta} bi bi-arrow-right`}></span>
              <span className={`${styles.seta2} bi bi-dash-lg`}></span>
              Recipes
            </a>
          </div>
          <div>
            <p className={styles.titulo_footer}>Institutional</p>
            <a href="/en/privacy-policy" className={styles.link_footer}>
              <span className={`${styles.seta} bi bi-arrow-right`}></span>
              <span className={`${styles.seta2} bi bi-dash-lg`}></span>
              Privacy Policy
            </a>
            <a href="/en/join-us" className={styles.link_footer}>
              <span className={`${styles.seta} bi bi-arrow-right`}></span>
              <span className={`${styles.seta2} bi bi-dash-lg`}></span>
              Join us
            </a>
            <a href="/en/contact-us" className={styles.link_footer}>
              <span className={`${styles.seta} bi bi-arrow-right`}></span>
              <span className={`${styles.seta2} bi bi-dash-lg`}></span>
              SAC
            </a>
          </div>
        </div>
      </div>
      <p className={styles.p_footer}>
        <i className={`bi bi-c-circle`}></i>2024 Nutriz
      </p>
    </footer>
  );
};

export default Footer;
