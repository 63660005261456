import React, { useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import styles from "./video.module.css";

const BackgroundVideo = (nav) => {
  let video;

  if (nav.web === "1") {
    video = require(`../../assets/video.webm`);
  } else {
    video = require(`../../assets/video.mp4`);
  }

  const pathRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    AOS.init({ duration: 1000 });

    const path = pathRef.current;
    const length = path.getTotalLength();
    path.style.strokeDasharray = length;
    path.style.strokeDashoffset = length;
    path.getBoundingClientRect();
    path.style.transition = "stroke-dashoffset 2s ease-in-out";
    path.style.strokeDashoffset = "0";

    const video = videoRef.current;
    video.addEventListener("canplaythrough", () => {
      video.play();
    });

    return () => {
      video.removeEventListener("canplaythrough", () => {
        video.play();
      });
    };
  }, []);

  return (
    <div className={styles.videocontainer}>
      <video
        ref={videoRef}
        className={styles.videobg}
        autoPlay
        loop
        muted
        playsInline
        preload="metadata" // Use metadata to reduce initial load
      >
        <source src={video} type="video/mp4" />
      </video>
      <section className={styles.secao1}>
        <h1 className={styles.h1} data-aos="fade-up" data-aos-duration="500">
          O
          <span className={styles.spanWrapper}>
            <svg
              className={`${styles.nectarScribble} ${styles.circle} ${styles.svg}`}
              viewBox="0 0 800 350"
              preserveAspectRatio="none"
            >
              <path
                ref={pathRef}
                strokeLinejoin="miter"
                fillOpacity="0"
                strokeMiterlimit="4"
                stroke="#df0022"
                strokeWidth="20"
                d="M253,-161 C253,-161 -284.78900146484375,-201.4600067138672 -376,-21 C-469,163 67.62300109863281,174.2100067138672 256,121 C564,34 250.82899475097656,-141.6929931640625 19.10700035095215,-116.93599700927734"
              ></path>
            </svg>
            frescor
          </span>
          que você deseja, a <br /> praticidade que você precisa.
        </h1>
        <p className={styles.p_secao1}>
          Alimentos congelados saborosos, práticos e de qualidade. Delicie-se!
        </p>
        <a href="#s1_home_id" className={styles.botao_conheca} id="s1_home_id">
          CONHEÇA
        </a>
      </section>
    </div>
  );
};

export default BackgroundVideo;
