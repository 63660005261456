import React, { useEffect } from "react";
import styles from "../../certificacoes/certificacoes.module.css";
import Header from "../../../components/espanhol/es_Header/es_header";
import Footer from "../../../components/espanhol/es_Footer/es_footer";
import img_certificacao from "../../../assets/img/c_DNV_FSSC22000.png";

function Certificacoes() {
  useEffect(() => {
    document.title = "Certificaciones - Nutriz";

    document.addEventListener("scroll", function () {
      const scrollPosition = window.scrollY;
      const img_cert = document.getElementById("img_cert");

      const speed = 0.3;

      // Inverte o movimento das imagens
      img_cert.style.transform = `translateY(${-scrollPosition * speed}px)`;
    });
  }, []);

  return (
    <main className={styles.main_cert}>
      <Header />
      <section className={styles.c_s1}>
        <h1 className={styles.h1_cert}>Certificaciones</h1>
        <p className={styles.p_cert}>
          Descubra nuestra seguridad, certificación de calidad y compromiso con
          la excelencia.
        </p>
        <a href="#c_s2" className={styles.svg_seta}>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 32 32"
          >
            <path
              d="M16 23.333c-0.111 0-0.22-0.028-0.319-0.083l-14.667-8c-0.324-0.176-0.443-0.58-0.267-0.904 0.176-0.323 0.584-0.443 0.904-0.267l14.348 7.827 14.349-7.827c0.321-0.176 0.727-0.056 0.904 0.267 0.175 0.324 0.057 0.728-0.267 0.904l-14.667 8c-0.1 0.055-0.209 0.083-0.32 0.083zM16 18c-0.111 0-0.22-0.028-0.319-0.083l-14.667-8c-0.324-0.176-0.443-0.58-0.267-0.904 0.176-0.323 0.584-0.443 0.904-0.267l14.348 7.827 14.349-7.827c0.321-0.176 0.727-0.056 0.904 0.267 0.175 0.324 0.057 0.728-0.267 0.904l-14.667 8c-0.1 0.055-0.209 0.083-0.32 0.083z"
              fill="white"
            ></path>
          </svg>
        </a>
      </section>

      <section className={styles.c_s2} id="c_s2">
        <div className={styles.c_s2_div}>
          <p>
            Nutriz lleva en su nombre el principio de su trabajo: Nutrir. Pero
            nuestra misión va mucho más allá de la nutrición y, partiendo de
            esta premisa, nos sentimos muy honrados de ser una empresa
            productora de vegetales congelados certificada FSSC 22.000 (Food
            Safety System Certification 22000), un esquema de certificación
            reconocido internacionalmente que demuestra el compromiso de Nutriz
            con la seguridad y calidad de los alimentos. , legalidad y mejora
            continua de sus procesos. El esquema de certificación FSSC 22000
            comprende los requisitos de ISO 22000, ISO/TS y requisitos
            adicionales desarrollados para garantizar la seguridad y calidad de
            los alimentos, una estructura de control de alto nivel, que es
            reconocida globalmente por la GFSI (Global Food Safety Initiative),
            una organización privada que trabaja para desarrollar estándares de
            seguridad y calidad alimentaria, facilitando el control y evitando
            duplicidades de auditorías y certificaciones. Esto representa no
            sólo un reconocimiento al cuidado de los alimentos desde la elección
            de la semilla hasta la mesa del consumidor, sino que también es una
            garantía de calidad y seguridad de que todos los alimentos
            producidos y comercializados por Nutriz pasan por rigurosos
            controles.
          </p>
          <p>
            Este es el resultado de constantes inversiones en automatización
            industrial, tecnologías de producción y la maquinaria más avanzada
            del mundo para garantizar eficiencia y calidad en las hortalizas
            producidas. Es el afán de producir alimentos de forma segura y
            sostenible. Es consecuencia de la mejora humana y de procesos, el
            compromiso y la dedicación que guían el trabajo de Nutriz en el
            mercado de vegetales congelados en Brasil desde 2003. Esto
            es Nutriz: sinónimo de producción y venta de alimentos sanos,
            sabrosos, prácticos y, sobre todo, de calidad, fabricados con total
            seguridad, alta tecnología de producción y respeto por el medio
            ambiente.
          </p>
        </div>
        <img
          src={img_certificacao}
          id="img_cert"
          className={styles.img_cert}
        ></img>
        <img src={img_certificacao} className={styles.img_cert_mobile}></img>
      </section>

      <Footer />
    </main>
  );
}

export default Certificacoes;
