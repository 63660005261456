import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // Importa o hook useLocation
import styles from "./header.module.css";
import img_logo from "../../assets/img/logo.png";
import instagram from "../../assets/img/instagram.png";
import facebook from "../../assets/img/facebook.png";
import linkedin from "../../assets/img/linkedin.png";
import br from "../../assets/img/br.png";
import us from "../../assets/img/us.png";
import es from "../../assets/img/es.png";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isDivVisible, setIsDivVisible] = useState(false);
  const [isSvgXVisible, setIsSvgXVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDivVisibility = () => {
    setIsDivVisible(!isDivVisible);
    setIsSvgXVisible(!isSvgXVisible);
  };

  return (
    <main>
      <section
        className={`${styles.cabecalho} ${
          scrolled ? styles.cabecalhoScrolled : ""
        }`}
      >
        <img
          src={img_logo}
          className={`${styles.logo} ${scrolled ? styles.logoScrolled : ""}`}
          onClick={() => {
            window.location.href = "/";
          }}
        />
        <button
          onClick={toggleDivVisibility}
          className={styles.icone_cabecalho_celular}
        >
          <svg
            className={`${styles.svg_icone} ${
              isSvgXVisible ? styles.hidden : ""
            }`}
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="#007943"
            viewBox="0 0 16 16"
          >
            <path d="M0 3.5A.5.5 0 0 1 .5 3h15a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5zm0 5A.5.5 0 0 1 .5 8h15a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5" />
          </svg>
          <svg
            className={`${styles.svg_x} ${isSvgXVisible ? "" : styles.hidden}`}
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="#007943"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
          </svg>
        </button>
        <div className={`${styles.div_cabecalho}`}>
          <a
            href="/"
            className={`${styles.links_cab} ${
              location.pathname === "/" ? styles.active : ""
            }`}
          >
            Home
          </a>
          <a
            href="/institucional"
            className={`${styles.links_cab} ${
              location.pathname === "/institucional" ? styles.active : ""
            }`}
          >
            Quem somos
          </a>
          <a
            href="/certificacoes"
            className={`${styles.links_cab} ${
              location.pathname === "/certificacoes" ? styles.active : ""
            }`}
          >
            Certificações
          </a>
          <a
            href="/produtos"
            className={`${styles.links_cab} ${
              location.pathname === "/produtos" ? styles.active : ""
            }`}
          >
            Produtos
          </a>
          <a
            href="/#secao6"
            className={`${styles.links_cab} ${
              location.pathname === "/#secao6" ? styles.active : ""
            }`}
          >
            Receitas
          </a>
          <a
            href="/trabalhe-conosco"
            className={`${styles.links_cab} ${
              location.pathname === "/trabalhe-conosco" ? styles.active : ""
            }`}
          >
            Trabalhe conosco
          </a>
          <a
            href="/contato"
            className={`${styles.botao_contato} ${
              location.pathname === "/contato" ? styles.active : ""
            }`}
          >
            Contato
          </a>
          <div className={styles.div_botoes_cabecalho}>
            <a href="https://www.instagram.com/nutrizalimentos/">
              <img src={instagram} />
            </a>
            <a href="https://www.facebook.com/nutrizalimentos">
              <img src={facebook} />
            </a>
            <a href="https://www.linkedin.com/company/nutriz-industria-ltda/">
              <img src={linkedin} />
            </a>
            <div className={styles.div_linguagens}>
              <a
                href="/"
                className={`${styles.links_cab} ${
                  !location.pathname.includes("/en") &&
                  !location.pathname.includes("/es")
                    ? styles.active
                    : ""
                }`}
              >
                <img src={br} className={styles.link_idioma} />
              </a>
              <a
                href="/en"
                className={`${styles.links_cab} ${
                  location.pathname.includes("/en") ? styles.active : ""
                }`}
              >
                <img src={us} className={styles.link_idioma} />
              </a>
              <a
                href="/es"
                className={`${styles.links_cab} ${
                  location.pathname.includes("/es") ? styles.active : ""
                }`}
              >
                <img src={es} className={styles.link_idioma} />
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* layout diferente para celular */}
      <div
        className={`${styles.div_cabecalho_celular}`}
        style={{ display: isDivVisible ? "flex" : "none" }}
      >
        <a
          href="/"
          className={`${styles.links_cab} ${
            location.pathname === "/" ? styles.active : ""
          }`}
        >
          Home
        </a>
        <a
          href="/institucional"
          className={`${styles.links_cab} ${
            location.pathname === "/institucional" ? styles.active : ""
          }`}
        >
          Quem somos
        </a>
        <a
          href="/certificacoes"
          className={`${styles.links_cab} ${
            location.pathname === "/certificacoes" ? styles.active : ""
          }`}
        >
          Certificações
        </a>
        <a
          href="/produtos"
          className={`${styles.links_cab} ${
            location.pathname === "/produtos" ? styles.active : ""
          }`}
        >
          Produtos
        </a>
        <a
          href="/#secao6"
          className={`${styles.links_cab} ${
            location.pathname === "/#secao6" ? styles.active : ""
          }`}
        >
          Receitas
        </a>
        <a
          href="/trabalhe-conosco"
          className={`${styles.links_cab} ${
            location.pathname === "/trabalhe-conosco" ? styles.active : ""
          }`}
        >
          Trabalhe conosco
        </a>
        <a
          href="/contato"
          className={`${styles.botao_contato} ${
            location.pathname === "/contato" ? styles.active : ""
          }`}
        >
          Contato
        </a>
        <div className={styles.div_botoes_cabecalho_mobile}>
          <a href="https://www.instagram.com/nutrizalimentos/">
            <img src={instagram} />
          </a>
          <a href="https://www.facebook.com/nutrizalimentos">
            <img src={facebook} />
          </a>
          <a href="https://www.linkedin.com/company/nutriz-industria-ltda/">
            <img src={linkedin} />
          </a>

          <a
            href="/"
            className={`${styles.links_cab} ${
              !location.pathname.includes("/en") &&
              !location.pathname.includes("/es")
                ? styles.active
                : ""
            }`}
          >
            <img src={br} className={styles.link_idioma} />
          </a>
          <a
            href="/en"
            className={`${styles.links_cab} ${
              location.pathname.includes("/en") ? styles.active : ""
            }`}
          >
            <img src={us} className={styles.link_idioma} />
          </a>
          <a
            href="/es"
            className={`${styles.links_cab} ${
              location.pathname.includes("/es") ? styles.active : ""
            }`}
          >
            <img src={es} className={styles.link_idioma} />
          </a>
        </div>
      </div>
    </main>
  );
};

export default Header;
