import React, { useEffect } from "react";
import styles from "./404.module.css";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";

function Erro() {
  useEffect(() => {
    document.title = "Página não encontrada";
  }, []);
  return (
    <>
      <Header />
      <div className={styles.page_404}>
        <h1>404</h1>
        <p>Page Not Found</p>
        <a href="/">BACK HOME <span className={`bi bi-arrow-right`}></span></a>
      </div>
      <Footer />
    </>
  );
}

export default Erro;
