import React, { useEffect } from "react";
import styles from "../../politicaDePrivacidade/PoliticaDePrivacidade.module.css";
import Header from "../../../components/english/en_Header/en_header";
import Footer from "../../../components/english/en_Footer/en_footer";


function PoliticaDePrivacidade () {
  useEffect(() => {
    document.title = "Privacy Policy - Nutriz";
  }, []);

  return (
    <main className={styles.main_qs}>
      <Header />
      <section className={styles.section_priv}>
        <h1 className={styles.h1_politica_priv}>Terms of Privacy, Cookies and Data Processing Policies</h1>
        <p>Thank you for visiting the Nutriz website, a food company, accessible through the domain nutriz.com.br. We value your privacy and strive to protect your personal information. This document describes how we collect, use and protect the data you share with us when accessing our website. We recommend that you carefully read this Privacy, Cookies and Data Processing Policies to understand how your information will be treated.</p>
        <h3>1. Information Collection</h3>
        <p>1.1 Personal Information: When visiting our website, we may ask you to provide personal information, such as name, email address, telephone number, resume, so that we can contact you or provide personalized services. This information will only be collected with your explicit consent and will be used in accordance with this policy.</p>
        <p>1.2 Browsing Information: We may collect non-personal information, such as IP address, browser type, internet service provider (ISP), pages visited, date and time of access. This information is collected automatically through cookies and other similar technologies, and is intended to improve your browsing experience on our website..</p>
        <h3>2. Use of Information</h3>
        <p>2.1 Use of Personal Information: The personal information you provide will be used for the following purposes:</p>
        <p>a) Provide personalized services according to your preferences;</p>
        <p>b) Process and respond to your requests, questions or complaints;</p>
        <p>c) Send relevant information about our products, promotions or events, provided that you have previously consented to receive such communications;</p>
        <p>d) Continuously improve our website and services, based on your opinions and feedback.</p>
        <p>2.2 Use of Navigation Information: Navigation information collected through cookies and similar technologies is used for statistical and analytical purposes, in order to better understand how our visitors interact with the website and improve its usability. This information may be shared in aggregate and anonymous form with third parties, such as advertising partners, for trend analysis and marketing purposes.</p>
        <h3>3. Cookies</h3>
        <p>3.1 Cookies: A cookie is a small text file that is stored on your device when you visit our website. We use cookies for various purposes, such as recognizing your device, remembering your preferences and providing personalized content. You can set your browser to refuse all cookies or to alert you when a cookie is being sent. However, please note that some parts of the website may not function properly if cookies are disabled.</p>
        <h3>4. Information Sharing</h3>
        <p>4.1 Sharing with Third Parties: We may share your personal information with third parties only in the following cases:</p>
        <p>a) When you expressly authorize us to do so;</p>
        <p>b) To comply with legal obligations, such as responding to requests from competent authorities;</p>
        <p>c) With third-party service providers who assist us in operating the website and providing services, provided that these third parties agree to keep your information confidential.</p>
        <h3>5. Data Security</h3>
        <p>5.1 Security: We implement appropriate security measures to protect your personal information against unauthorized access, alteration, disclosure or destruction. However, no method of electronic transmission or storage is 100% secure, therefore we cannot guarantee the absolute security of your information.</p>
        <h3>6. Changes to this Policy</h3>
        <p>6.1 Updates: We may update this Privacy Policy periodically to reflect changes in our privacy practices. We recommend that you review this policy regularly to be aware of any changes.</p>
        <h3>7. Contact</h3>
        <p>7.1 Contact: If you have any questions, concerns or requests related to this Privacy Policy, please contact us through the channels available on our website.</p>
        <p>By continuing to use our website, you agree to the terms of this Privacy, Cookies and Data Processing Policy.</p>
      </section>
      

      <Footer />
    </main>
  );
}

export default PoliticaDePrivacidade;
