import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import styles from "./es_home.module.css";
import imagem_brocolis_secao2 from "../../../assets/img/imagem_brocolis_secao2.png";
import banner_s5_1 from "../../../assets/img/banner_s5_1.png";
import banner_s5_2 from "../../../assets/img/banner_s5_2.png";
import banner_s5_3 from "../../../assets/img/banner_s5_3.png";
import banner_s5_4 from "../../../assets/img/banner_s5_4.png";
import BackgroundVideo from "../../../components/espanhol/es_video/es_video";
import Header from "../../../components/espanhol/es_Header/es_header";
import Footer from "../../../components/espanhol/es_Footer/es_footer";
import Popup from "../../../components/espanhol/es_popup/es_popup";

function Home() {
  let nav = "1"
  if (window.innerWidth < 500) {
    nav = "2";
  }

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPopupOpen(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    AOS.init({ duration: 1000 });
    document.title = "Nutriz Alimentos Congelados";

    const hash = window.location.hash;
    if (hash === "/en/#secao6") {
      window.location.href = "https://nutriz.com.br/en/#secao6";
    }

    // efeito de rolagem da imagem
    const handleScroll = () => {
      const section = document.querySelector(`.${styles.secao4}`);
      if (section) {
        const scrollPosition = window.pageYOffset;
        const windowHeight = window.innerHeight;
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        if (
          scrollPosition + windowHeight > sectionTop &&
          scrollPosition < sectionTop + sectionHeight
        ) {
          const offset =
            (scrollPosition + windowHeight - sectionTop) /
            (3 * (windowHeight + sectionHeight));

          section.style.setProperty("--bg-position", `${80 - offset * 80}%`);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <main className={styles.main_home}>
      <Header />
      <BackgroundVideo web={nav} />

      {/* {isPopupOpen && <Popup handleClose={closePopup} />} */}

      <section className={styles.secao2}>
        <div className={styles.div_textos_s2}>
          <p
            className={styles.titulo_s2}
            data-aos="fade-up"
            data-aos-duration="500"
          >
            Congelados Nutriz:
          </p>
          <p
            className={styles.titulo_s2}
            data-aos="fade-up"
            data-aos-duration="750"
          >
            Sabor y frescura
          </p>
          <p
            className={styles.titulo_s2}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            en tu mesa
          </p>

          <p>
            Estamos presentes en todo Brasil, adoptando un enfoque innovador y
            utilizando equipos modernos para garantizar la calidad en todas las
            etapas de nuestra producción, desde la selección de la semilla hasta
            la entrega al cliente.
          </p>
          <p>
            Nuestros productos son amigables con el medio ambiente, manteniendo
            las características nutricionales, sabor y textura de los alimentos.
          </p>
          <p>
            Somos un referente en el sector de los congelados y destacamos por
            nuestro compromiso y seriedad.
          </p>
          <a href="/es/quienes-somos" className={styles.botao_saibamais_s2}>
            SEPA MAS
          </a>
        </div>
        <img src={imagem_brocolis_secao2}></img>
      </section>

      <section className={styles.secao3}>
        <h1>Descubre las principales diferencias de los alimentos Nutriz:</h1>
        <div className={styles.div_s3}>
          <div className={styles.div1_s3}>
            <p className={styles.titulo_div1_s3}>
              Alimentos 100% naturales, libres de aditivos y conservantes
            </p>
            <p className={styles.p_div1_s3}>
              Seleccionados, limpios, pelados, cortados y congelados
              individualmente. Sin colorantes ni conservantes, preservando la
              autenticidad y la calidad.
            </p>
          </div>
          <div className={styles.div2_s3}>
            <p className={styles.titulo_div2_s3}>Practicidad y economía</p>
            <p className={styles.p_div2_s3}>
              Sin necesidad de descongelar antes de la preparación y con
              posibilidad de devolver el resto del envase al congelador,
              ¡evitando desperdicios!
            </p>
          </div>
          <div className={styles.div3_s3}>
            <p className={styles.titulo_div3_s3}>
              Rápida preparación y calidad garantizada
            </p>
            <p className={styles.p_div3_s3}>
              Nuestros alimentos ofrecen una rápida preparación, garantizando
              calidad nutricional y microbiológica.
            </p>
          </div>
        </div>
      </section>

      <section className={styles.secao4}>
        <p>
          ¡Los
          <span className={styles.span_s4}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              focusable="false"
              viewBox="0 0 12 12"
            >
              <path stroke="currentColor" strokeLinecap="round" d="M3 6.5h6" />
            </svg>
            mejores
          </span>
          alimentos congelados!
        </p>
      </section>

      <section className={styles.secao5}>
        <a href="/es/productos" data-aos="fade-left" data-aos-delay="50">
          <div className={styles.img_container}>
            <img src={banner_s5_1} alt="Line Institutional" />
          </div>
          <p>Línea</p>
          <p className={styles.s2_descr}>Institucional</p>
        </a>
        <a href="/es/productos" data-aos="fade-left" data-aos-delay="150">
          <div className={styles.img_container}>
            <img src={banner_s5_2} alt="Línea Wholesale" />
          </div>
          <p>Línea</p>
          <p className={styles.s2_descr}>Atacado</p>
        </a>
        <a href="/es/productos" data-aos="fade-left" data-aos-delay="250">
          <div className={styles.img_container}>
            <img src={banner_s5_3} alt="Línea Retail" />
          </div>
          <p>Línea</p>
          <p className={styles.s2_descr}>Minorista</p>
        </a>
        <a href="/es/productos" data-aos="fade-left" data-aos-delay="350">
          <div className={styles.img_container}>
            <img src={banner_s5_4} alt="Línea Bom Apetite" />
          </div>
          <p>Línea</p>
          <p id="secao6" className={styles.s2_descr}>
            Bom Apetite
          </p>
        </a>
      </section>

      <section className={styles.secao6}>
        <p data-aos="fade-up" data-aos-delay="30">
        Inspírate con
          <br />
          <span className={styles.span_receitas}>
            <svg
              className={styles.svg_receitas}
              viewBox="-400 -55 730 60"
              preserveAspectRatio="none"
            >
              <path
                d="m -383.25 -6 c 55.25 -22 130.75 -33.5 293.25 -38 c 54.5 -0.5 195 -2.5 401 15"
                stroke="#007943"
                pathLength="1"
                strokeWidth="20"
                fill="none"
              ></path>
            </svg>
            ¡Deliciosas recetas
          </span>{" "}
          para preparar con tus alimentos Nutriz!
        </p>
        <a href="https://www.instagram.com/stories/highlights/18240165124258007">CONTROLAR!</a>
      </section>

      <Footer />
    </main>
  );
}

export default Home;
