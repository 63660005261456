import React from "react";
import styles from "./footer.module.css";
import logo_branca from "../../assets/img/Logo-negativo-white.png";
import logo22000 from "../../assets/img/DNV_FSSC-22000.png";

const Footer = () => {
  return (
    <footer className={styles.footer_home}>
      <div className={styles.footer_div}>
        <div className={styles.footer_div_imagens}>
          <img src={logo_branca} className={styles.logo_branca}></img>
          <img src={logo22000} className={styles.logo22000}></img>
        </div>
        <div className={styles.div_mapa_inst}>
          <div>
            <p className={styles.titulo_footer}>Mapa do site</p>
            <a href="/" className={styles.link_footer}>
              <span className={`${styles.seta} bi bi-arrow-right`}></span>
              <span className={`${styles.seta2} bi bi-dash-lg`}></span>
              Home
            </a>
            <a href="/institucional" className={styles.link_footer}>
              <span className={`${styles.seta} bi bi-arrow-right`}></span>
              <span className={`${styles.seta2} bi bi-dash-lg`}></span>
              Quem somos
            </a>
            <a href="/produtos" className={styles.link_footer}>
              <span className={`${styles.seta} bi bi-arrow-right`}></span>
              <span className={`${styles.seta2} bi bi-dash-lg`}></span>
              Produtos
            </a>
            <a href="/#secao6" className={styles.link_footer}>
              <span className={`${styles.seta} bi bi-arrow-right`}></span>
              <span className={`${styles.seta2} bi bi-dash-lg`}></span>
              Receitas
            </a>
          </div>
          <div>
            <p className={styles.titulo_footer}>Institucional</p>
            <a href="/politica-de-privacidade" className={styles.link_footer}>
              <span className={`${styles.seta} bi bi-arrow-right`}></span>
              <span className={`${styles.seta2} bi bi-dash-lg`}></span>
              Política de privacidade
            </a>
            <a href="/trabalhe-conosco" className={styles.link_footer}>
              <span className={`${styles.seta} bi bi-arrow-right`}></span>
              <span className={`${styles.seta2} bi bi-dash-lg`}></span>
              Trabalhe conosco
            </a>
            <a href="/contato" className={styles.link_footer}>
              <span className={`${styles.seta} bi bi-arrow-right`}></span>
              <span className={`${styles.seta2} bi bi-dash-lg`}></span>
              SAC
            </a>
          </div>
        </div>
      </div>
      <p className={styles.p_footer}>
        <i className={`bi bi-c-circle`}></i>2024 Nutriz
      </p>
    </footer>
  );
};

export default Footer;
