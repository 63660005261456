import React, { useEffect } from "react";
import styles from "../../certificacoes/certificacoes.module.css";
import Header from "../../../components/english/en_Header/en_header";
import Footer from "../../../components/english/en_Footer/en_footer";
import img_certificacao from "../../../assets/img/c_DNV_FSSC22000.png";

function Certificacoes() {
  useEffect(() => {
    document.title = "Certifications - Nutriz";

    document.addEventListener("scroll", function () {
      const scrollPosition = window.scrollY;
      const img_cert = document.getElementById("img_cert");

      const speed = 0.3;

      // Inverte o movimento das imagens
      img_cert.style.transform = `translateY(${-scrollPosition * speed}px)`;
    });
  }, []);

  return (
    <main className={styles.main_cert}>
      <Header />
      <section className={styles.c_s1}>
        <h1 className={styles.h1_cert}>Commitment to Excellence</h1>
        <p className={styles.p_cert}>Discover our quality certifications.</p>
        <a href="#c_s2" className={styles.svg_seta}>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 32 32"
          >
            <path
              d="M16 23.333c-0.111 0-0.22-0.028-0.319-0.083l-14.667-8c-0.324-0.176-0.443-0.58-0.267-0.904 0.176-0.323 0.584-0.443 0.904-0.267l14.348 7.827 14.349-7.827c0.321-0.176 0.727-0.056 0.904 0.267 0.175 0.324 0.057 0.728-0.267 0.904l-14.667 8c-0.1 0.055-0.209 0.083-0.32 0.083zM16 18c-0.111 0-0.22-0.028-0.319-0.083l-14.667-8c-0.324-0.176-0.443-0.58-0.267-0.904 0.176-0.323 0.584-0.443 0.904-0.267l14.348 7.827 14.349-7.827c0.321-0.176 0.727-0.056 0.904 0.267 0.175 0.324 0.057 0.728-0.267 0.904l-14.667 8c-0.1 0.055-0.209 0.083-0.32 0.083z"
              fill="white"
            ></path>
          </svg>
        </a>
      </section>

      <section className={styles.c_s2} id="c_s2">
        <div className={styles.c_s2_div}>
          <p>
            Nutriz carries in its name the principle of its work: Nourishment.
            But our mission goes far beyond nutrition, and from this premise, we
            feel deeply honored to be a certified ISO 22000 frozen vegetable
            production company, an international standard that aims to align all
            businesses involved in the food chain and offer safe products.{" "}
            <br></br>
            <br></br>
            The ISO 22000 certification, along with FSSC 22000 (Food Safety
            System Certification 22000), a system that encompasses ISO 22000
            requirements, ISO/TS, and additional requirements developed to
            ensure food safety and quality, is recognized by the Global Food
            Safety Initiative (GFSI), a private organization working on the
            development of food safety and quality standards, facilitating
            control and avoiding duplication of audits and certifications. This
            not only represents recognition for caring for food from seed
            selection to the consumer’s table but also ensures the quality and
            safety of all foods produced and marketed by Nutriz.
          </p>
          <p>
          This is the result of constant investments in industrial automation, production technologies, and the most advanced machinery in the world. It’s the care in producing food safely and sustainably. It’s a consequence of human and process improvement, commitment, and dedication that have guided Nutriz’s work in the frozen vegetable market in Brazil since 2003. This is Nutriz: synonymous with the production and marketing of healthy, delicious, practical, and, above all, quality foods, manufactured with total safety, advanced production technology, and respect for the environment!


          </p>
        </div>
        <img
          src={img_certificacao}
          id="img_cert"
          className={styles.img_cert}
        ></img>
        <img src={img_certificacao} className={styles.img_cert_mobile}></img>
      </section>

      <Footer />
    </main>
  );
}

export default Certificacoes;
