import React, { useEffect } from "react";
import styles from "../../produtos/produtos.module.css";
import Header from "../../../components/english/en_Header/en_header";
import Footer from "../../../components/english/en_Footer/en_footer";
import inst_brocolis from "../../../assets/img/produtos/INST_Brocolis.png";
import inst_brocolis_2kg from "../../../assets/img/produtos/INST_brocolis_2kg.png";
import inst_couveflor from "../../../assets/img/produtos/INST_Couveflor.png";
import inst_ErvilhaFresca from "../../../assets/img/produtos/INST_ErvilhaFresca.png";
import inst_seletadelegumes from "../../../assets/img/produtos/INST_seletadelegumes.png";
import inst_jardineiracomervilhas from "../../../assets/img/produtos/INST_jardineiracomervilhas.png";
import inst_jardineirapanache from "../../../assets/img/produtos/INST_jardineirapanache.png";
import inst_jardineiraprimavera from "../../../assets/img/produtos/INST_jardineiraprimavera.png";
import inst_polentapalito from "../../../assets/img/produtos/INST_polentapalito.png";
import inst_PolentaNoisette from "../../../assets/img/produtos/INST_PolentaNoisette.png";
import inst_vagemInteira from "../../../assets/img/produtos/INST_VagemInteira.png";
import inst_vagemPedaco from "../../../assets/img/produtos/INST_VagemPedaco.png";
import inst_espinafrePicado from "../../../assets/img/produtos/INST_EspinafrePicado.png";
import inst_espinafreFolhas from "../../../assets/img/produtos/INST_EspinafreFolhas.png";
import inst_cenouraBaby from "../../../assets/img/produtos/INST_CenouraBaby.png";
import inst_cenouraCubos from "../../../assets/img/produtos/INST_cenouracubos.png";
import inst_couveDeBruxelas from "../../../assets/img/produtos/INST_CouveDeBruxelas.png";
import inst_mandiocaPalitos from "../../../assets/img/produtos/INST_MandiocaPalitos.png";
import inst_mandiocaPedaco from "../../../assets/img/produtos/INST_MandiocaPedaco.png";
import inst_aboboraCabotia from "../../../assets/img/produtos/INST_AbóboraCabotiá.png";
import inst_aneisDeCebola from "../../../assets/img/produtos/INST_AnéisdeCebola.png";
import inst_batataPalito from "../../../assets/img/produtos/INST_BatataPalito.png";
import inst_couveFlorMacerata from "../../../assets/img/produtos/INST_CouveFlorMacerata.png";
import atac_duetoDeLegumes from "../../../assets/img/produtos/ATAC_DuetoDeLegumes.png";
import atac_brocolis from "../../../assets/img/produtos/ATAC_brocolis.png";
import atac_couve_flor from "../../../assets/img/produtos/ATAC_couve_flor.png";
import atac_ervilha_fresca from "../../../assets/img/produtos/ATAC_ervilha_fresca.png";
import atac_milho from "../../../assets/img/produtos/ATAC_Milho.png";
import atac_jardineiraComErvilhas from "../../../assets/img/produtos/ATAC_jardineira_com_ervilhas.png";
import atac_jardineiraPanache from "../../../assets/img/produtos/ATAC_JardineiraPanache.png";
import atac_alhoInteiro from "../../../assets/img/produtos/ATAC_AlhoInteiro.png";
import atac_alhoPicado from "../../../assets/img/produtos/ATAC_AlhoPicado.png";
import atac_polentapalitos from "../../../assets/img/produtos/ATAC_polentaPalitos.png";
import atac_mandiocaPalitos from "../../../assets/img/produtos/ATAC_MandiocaPalito.png";
import atac_mandiocaPedaco from "../../../assets/img/produtos/ATAC_MandiocaPedaco.png";
import atac_paoDeQueijoTradicional from "../../../assets/img/produtos/ATAC_PaoDeQueijoTradicional.png";
import atac_paoDeQueijoCoquetel from "../../../assets/img/produtos/ATAC_PaoDeQueijoCoquetel.png";
import atac_paoDeQueijoSuperLanche from "../../../assets/img/produtos/ATAC_PaoDeQueijoSuperLanche.png";
import var_brocolis from "../../../assets/img/produtos/VAR_brocolis.png";
import var_couve_flor from "../../../assets/img/produtos/VAR_couve_flor.png";
import var_ervilha_fresca from "../../../assets/img/produtos/VAR_ervilha_fresca.png";
import var_seleta_de_legumes from "../../../assets/img/produtos/VAR_seleta_de_legumes.png";
import VAR_jardineira_panache from "../../../assets/img/produtos/VAR_jardineira_panache.png";
import var_polenta_palitos from "../../../assets/img/produtos/VAR_polenta_palitos.png";
import ba_brocolis from "../../../assets/img/produtos/BA_Brocolis.png";
import ba_couveFlor from "../../../assets/img/produtos/BA_CouveFlor.png";
import ba_ervilha from "../../../assets/img/produtos/BA_Ervilha.png";
import ba_jardineiraErvilha from "../../../assets/img/produtos/BA_JardineiraErvilha.png";
import ba_jardineiraPanache from "../../../assets/img/produtos/BA_JardineiraPanache.png";
import AOS from "aos";
import "aos/dist/aos.css";

function Produtos() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    document.title = "Products - Nutriz";

    const hash = window.location.hash;
    if (hash === "#bom-apetite") {
      setTimeout(() => {
        window.location.href = "https://nutriz.com.br/#bom-apetite";
      }, 10000);
    }
  }, []);

  return (
    <main className={styles.main_produtos}>
      <Header />
      <section className={styles.p_s1}>
        <p></p>
        <div>
          <h1 className={styles.h1_prod}>Nutriz’s Foods:
          </h1>
          <p className={styles.p_s1_descr}>
          Safety, Health, and Flavor in Every Bite.

          </p>
        </div>
        <div className={styles.p_div_links}>
          <a href="#institucional">Institutional</a>
          <a href="#atacado">Wholesale</a>
          <a href="#varejo">Retail</a>
          <a href="#bom-apetite">Bom Apetite</a>
        </div>
        <div className={styles.p_div_links_mobile}>
          <div>
            <a href="#institucional">Institutional</a>
            <a href="#varejo">Retail</a>
          </div>
          <div>
            <a href="#atacado">Wholesale</a>
            <a href="#bom-apetite">Bom Apetite</a>
          </div>
        </div>
      </section>

      <p id="institucional" className={styles.p_id}></p>
      <section>
        <h1 className={styles.prod_h1_s2}>Institutional Line
        </h1>
        <div className={styles.prod_s2}>
          <div className={styles.container_produtos}>
            <a href="/en/product/brocolis-inst">
              <img src={inst_brocolis}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Broccoli 2.5kg</p>
          </div>
          
          <div className={styles.container_produtos}>
            <a href="/en/product/brocolis-inst-2kg">
              <img src={inst_brocolis_2kg}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Broccoli 2kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/en/product/couve-flor-inst">
              <img src={inst_couveflor}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Cauliflower 2.5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/en/product/ervilha-fresca-inst">
              <img src={inst_ErvilhaFresca}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Fresh Peas 2.5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/en/product/seleta-de-legumes-inst">
              <img src={inst_seletadelegumes}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Vegetable Selection 2.5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/en/product/jardineira-com-ervilhas-inst">
              <img src={inst_jardineiracomervilhas}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Garden with Peas 2.5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/en/product/jardineira-panache-inst">
              <img src={inst_jardineirapanache}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Panache Garden 2.5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/en/product/jardineira-primavera-inst">
              <img src={inst_jardineiraprimavera}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Spring Garden 2.5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/en/product/polenta-palito-inst">
              <img src={inst_polentapalito}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Polenta Sticks 2.5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/en/product/polenta-noisette-inst">
              <img src={inst_PolentaNoisette}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Polenta Noisette 2.5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/en/product/vagem-inteira-inst">
              <img src={inst_vagemInteira}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Whole Pod 2.5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/en/product/vagem-pedacos-inst">
              <img src={inst_vagemPedaco}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Pod Pieces 2.5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/en/product/espinafre-picado-inst">
              <img src={inst_espinafrePicado}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Chopped Spinach 2.5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/en/product/espinafre-folhas-inst">
              <img src={inst_espinafreFolhas}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Spinach Leaves 2.5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/en/product/cenoura-baby-inst">
              <img src={inst_cenouraBaby}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Baby Carrot 2.5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/en/product/cenoura-em-cubos-inst">
              <img src={inst_cenouraCubos}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Diced Carrots 2.5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/en/product/couve-de-bruxelas-inst">
              <img src={inst_couveDeBruxelas}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Brussels Sprouts 2.5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/en/product/mandioca-palitos-inst">
              <img src={inst_mandiocaPalitos}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Cassava Sticks 2.5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/en/product/mandioca-pedacos-inst">
              <img src={inst_mandiocaPedaco}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Cassava Pieces 2.5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/en/product/abobora-cabotia-inst">
              <img src={inst_aboboraCabotia}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Cabotiá Squash 2.5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/en/product/aneis-de-cebola-inst">
              <img src={inst_aneisDeCebola}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Onion Rings Leduc 1.1kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/en/product/batata-palito-inst">
              <img src={inst_batataPalito}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Seasoned French Fries 2kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/en/product/couve-flor-branqueado-inst">
              <img src={inst_couveFlorMacerata}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Cauliflower Macerata 2kg</p>
          </div>
        </div>
      </section>

      <p id="atacado" className={styles.p_id}></p>
      <section>
        <h1
          className={styles.prod_h1_s2}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          Wholesale Line

        </h1>
        <div className={styles.prod_s2}>
          <div className={styles.container_produtos}>
            <a href="/en/product/brocolis-atac">
              <img src={atac_brocolis}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Broccoli 1.02kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/en/product/couve-flor-atac">
              <img src={atac_couve_flor}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Cauliflower 1.02kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/en/product/ervilha-fresca-atac">
              <img src={atac_ervilha_fresca}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Fresh Peas 1.1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/en/product/milho-atac">
              <img src={atac_milho}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Corn Grains 1.02kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/en/product/jardineira-com-ervilhas-atac">
              <img src={atac_jardineiraComErvilhas}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Garden with Peas 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/en/product/jardineira-panache-atac">
              <img src={atac_jardineiraPanache}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Panache Garden 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/en/product/alho-inteiro-atac">
              <img src={atac_alhoInteiro}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Whole Garlic 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/en/product/alho-picado-atac">
              <img src={atac_alhoPicado}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Chopped Garlic 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/en/product/polenta-palitos-atac">
              <img src={atac_polentapalitos}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Polenta Sticks 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/en/product/mandioca-palito-atac">
              <img src={atac_mandiocaPalitos}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Cassava Sticks 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/en/product/mandioca-pedaco-atac">
              <img src={atac_mandiocaPedaco}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Cassava Pieces 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/en/product/pao-de-queijo-tradicional-atac">
              <img src={atac_paoDeQueijoTradicional}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Traditional Cheese Bread 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/en/product/pao-de-queijo-coquetel-atac">
              <img src={atac_paoDeQueijoCoquetel}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Cocktail Cheese Bread 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/en/product/pao-de-queijo-super-lanche-atac">
              <img src={atac_paoDeQueijoSuperLanche}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Cheese Bread Super Snack 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/en/product/dueto-de-legumes-atac">
              <img src={atac_duetoDeLegumes}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p className={styles.paodequeijo}>Vegetable Duo 1kg</p>
          </div>
        </div>
      </section>

      <p id="varejo" className={styles.p_id}></p>
      <section>
        <h1
          className={styles.prod_h1_s2}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          Retail Line
        </h1>
        <div className={styles.prod_s2}>
          <div className={styles.container_produtos}>
            <a href="/en/product/brocolis-var">
              <img src={var_brocolis}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Broccoli 300g</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/en/product/couve-flor-var">
              <img src={var_couve_flor}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Cauliflower 300g</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/en/product/ervilha-fresca-var">
              <img src={var_ervilha_fresca}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Fresh Peas 300g</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/en/product/seleta-de-legumes-var">
              <img src={var_seleta_de_legumes}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Vegetable Selection 300g</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/en/product/jardineira-panache-var">
              <img src={VAR_jardineira_panache}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Panache Garden 300g</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/en/product/polenta-palito-var">
              <img src={var_polenta_palitos}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Polenta Sticks 400g</p>
          </div>
        </div>
      </section>

      <p id="bom-apetite" className={styles.p_id}></p>
      <section>
        <h1
          className={styles.prod_h1_s2}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          Bom Apetite Line
        </h1>
        <div className={styles.prod_s2}>
          <div className={styles.container_produtos}>
            <a href="/en/product/brocolis-bom-apetite">
              <img src={ba_brocolis}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Broccoli 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/en/product/couve-flor-bom-apetite">
              <img src={ba_couveFlor}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Cauliflower 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/en/product/ervilha-bom-apetite">
              <img src={ba_ervilha}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Pea 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/en/product/jardineira-com-ervilhas-bom-apetite">
              <img src={ba_jardineiraErvilha}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Garden with Peas 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/en/product/jardineira-panache-bom-apetite">
              <img src={ba_jardineiraPanache}></img>
              <p className={styles.leia_mais}>READ MORE</p>
            </a>
            <p>Panache Garden 1kg</p>
          </div>
        </div>
      </section>

      <Footer />
    </main>
  );
}

export default Produtos;
