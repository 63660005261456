import React, { useEffect } from "react";
import styles from "../../quemSomos/quemSomos.module.css";
import Header from "../../../components/espanhol/es_Header/es_header";
import Footer from "../../../components/espanhol/es_Footer/es_footer";
import qs_s2_img from "../../../assets/img/qs_s2_img.png";
import qs_s3_img from "../../../assets/img/qs_s3_img.png";

function QuemSomos() {
  useEffect(() => {
    document.title = "Institucional - Nutriz";

    document.addEventListener("scroll", function () {
      const scrollPosition = window.scrollY;
      const s2Img = document.getElementById("qs_s2_img");
      const s3Img = document.getElementById("qs_s3_img");

      // Ajusta a velocidade e direção do movimento
      const speed = 0.1;

      // Inverte o movimento das imagens
      s2Img.style.transform = `translateY(${-scrollPosition * speed}px)`;
      s3Img.style.transform = `translateY(${-scrollPosition * speed}px)`;
    });
  }, []);

  return (
    <main className={styles.main_qs}>
      <Header />
      <section className={styles.backgroundLavoura}>
        <h1 className={styles.h1_animation_qs}>
          Nutriz: una historia de excelencia en alimentos congelados
        </h1>
        <a href="#qs_s2" className={styles.svg_seta}>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 32 32"
          >
            <path
              d="M16 23.333c-0.111 0-0.22-0.028-0.319-0.083l-14.667-8c-0.324-0.176-0.443-0.58-0.267-0.904 0.176-0.323 0.584-0.443 0.904-0.267l14.348 7.827 14.349-7.827c0.321-0.176 0.727-0.056 0.904 0.267 0.175 0.324 0.057 0.728-0.267 0.904l-14.667 8c-0.1 0.055-0.209 0.083-0.32 0.083zM16 18c-0.111 0-0.22-0.028-0.319-0.083l-14.667-8c-0.324-0.176-0.443-0.58-0.267-0.904 0.176-0.323 0.584-0.443 0.904-0.267l14.348 7.827 14.349-7.827c0.321-0.176 0.727-0.056 0.904 0.267 0.175 0.324 0.057 0.728-0.267 0.904l-14.667 8c-0.1 0.055-0.209 0.083-0.32 0.083z"
              fill="white"
            ></path>
          </svg>
        </a>
      </section>

      <section className={styles.qs_s2} id="qs_s2">
        <div className={styles.qs_s2_div1}>
          <p className={styles.qs_s2_titulo}>
            La elección consciente de alimentos congelados de calidad
          </p>
          <p>
            Ganamos presencia en Brasil a través de un enfoque innovador y
            tecnologías avanzadas que nos distinguen en el mercado.
          </p>
          <p>
            Garantizamos la calidad de nuestros productos durante todo el año,
            desde la elección de las semillas hasta la entrega, con control de
            residuos y mano de obra responsable. Somos reconocidos no sólo por
            nuestra rapidez de procesamiento, sino también por nuestra frescura
            y calidad.
          </p>
          <p>
            Con una amplia gama de alimentos congelados, libres de conservantes
            y en envases 100% reciclables, brindamos practicidad, salud y
            respeto al medio ambiente, con una selección de insumos naturales
            para una nutrición saludable que mantiene el sabor y los nutrientes
            a través de un proceso de alta tecnología. congelación profunda.
          </p>
        </div>
        <img src={qs_s2_img} id="qs_s2_img" className={styles.qs_s2_img}></img>
        <img src={qs_s2_img} className={styles.qs_s2_img_mobile}></img>
      </section>

      <section className={styles.qs_s3}>
        <img src={qs_s3_img} id="qs_s3_img" className={styles.qs_s3_img}></img>
        <img src={qs_s3_img} className={styles.qs_s3_img_mobile}></img>
        <div className={styles.qs_s3_div1}>
          <p className={styles.qs_s3_titulo}>
            Una trayectoria de crecimiento e innovación
          </p>
          <p>
            Desde nuestra fundación en 2003, nos hemos consolidado como un
            referente en el mercado de los alimentos congelados. En 2008 abrimos
            nuestra propia cámara frigorífica, mejorando la logística y
            ganándonos la confianza de nuestros clientes. En 2011, lanzamos una
            línea de productos al por mayor, seguido del rediseño visual de
            todos los envases en 2012.
          </p>
          <p>
            Con el tiempo, creamos las marcas BOM APETITE y MACERATA, diseñadas
            para ayudar a Nutriz a tener presencia estratégica en el mercado,
            siendo utilizadas para la venta de algunos productos de la línea
            Nutriz y también para la distribución de algunos alimentos en
            ciertas regiones de Brasil.
          </p>
          <p>
            Así, desde nuestros inicios, hemos seguido evolucionando,
            ampliándonos y ofreciendo cada vez más calidad y variedad a todos
            nuestros clientes.
          </p>
        </div>
      </section>

      <svg
        aria-hidden="true"
        fill="#df231d"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 50"
        preserveAspectRatio="none"
      >
        <path d="M0 40c0 0 200-25 500-25s500 25 500 25V0H0v50z"></path>
      </svg>

      <section className={styles.qs_s4}>
        <div className={styles.div_qs_s4}>
          <h3>Misión</h3>
          <p>
            Producir, comercializar y distribuir alimentos seguros, saludables y
            sabrosos con eficiencia y alta tecnología
          </p>
        </div>
        <div className={styles.div_qs_s4}>
          <h3>Visión</h3>
          <p>
            Ser referencia en alimentos congelados y vegetales y hacer que las
            marcas Nutriz sean sinónimo de productos de calidad a nivel
            brasileño
          </p>
        </div>
        <div className={styles.div_qs_s4}>
          <h3>Valores</h3>
          <p>
            Ética, reputación, transparencia, responsabilidad social y respeto
            al medio ambiente
          </p>
        </div>
        <div className={styles.div_qs_s4}>
          <h3>Filosofía</h3>
          <p>
            Disciplina, compromiso, trabajo en equipo, innovación continua y
            respeto absoluto a la legislación, clientes, empleados, proveedores
            y socios
          </p>
        </div>
        <div className={styles.div_qs_s4}>
          <h3>Objetivos</h3>
          <p>Eficiencia operativa y comercial, rentabilidad y crecimiento</p>
        </div>
      </section>

      <section className={styles.qs_s5}>
        <h2 className={styles.qs_s5}>
          Política Integrada de
          <span>
            <span className={styles.span_sub_qs_s5}>Calidad y Seguridad</span>
            <svg
              className={styles.svg_s5}
              viewBox="-400 -55 730 60"
              preserveAspectRatio="none"
            >
              <path
                d="m -383.25 -6 c 55.25 -22 130.75 -33.5 293.25 -38 c 54.5 -0.5 195 -2.5 401 15"
                stroke="#df231d"
                pathLength="1"
                strokeWidth="14"
                fill="none"
              ></path>
            </svg>
          </span>
          Alimentaria
        </h2>

        <p>
          Nutriz Indústria e Comércio de Alimentos Ltda, en su producción de
          vegetales congelados, opera a través del compromiso, implicación y
          desarrollo de sus empleados para la mejora proceso continuo de gestión
          de la calidad y seguridad de los alimentos. A través de sus diversos
          canales de comunicación, asegura una buena relación con sus grupos de
          interés. Mantiene la eficacia y la eficiencia. de los procesos de
          producción para superar las expectativas del cliente consumidores y
          cumplir con los requisitos legales y del cliente, maximizando así el
          valor de la empresa.
        </p>
      </section>

      <section className={styles.qs_s6}>
        <h2>
          Alimentos de calidad, garantía de satisfacción del cliente y
          responsabilidad socioambiental
        </h2>
        <p>
          Buscamos la satisfacción del cliente entregando productos saludables,
          esforzándonos siempre en mejorar los procesos, buscando constantemente
          integrar a los proveedores para promover el crecimiento humano.
          Pasamos por todas las etapas con respeto al medio ambiente,
          contribuyendo al desarrollo y progreso social y económico de Brasil
          con alimentos de calidad.
        </p>
      </section>

      <section className={styles.qs_s7}>
        <h2>
          ¡Pruébalo ahora y descubre la calidad y el sabor de los productos
          Nutriz!
        </h2>
        <a href="/es/productos" className={styles.link_conheca_prod}>
          <span className={`${styles.seta} bi bi-arrow-right`}></span>
          <span className={`${styles.seta2} bi bi-dash-lg`}></span>
          Descubre nuestros productos
        </a>
      </section>

      <Footer />
    </main>
  );
}

export default QuemSomos;
