import React, { useEffect, useState } from "react";
import styles from "../../trabalheConosco/trabalheConosco.module.css";
import Header from "../../../components/espanhol/es_Header/es_header";
import Footer from "../../../components/espanhol/es_Footer/es_footer";
import emailjs from "@emailjs/browser";

function TrabalheConosco() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [vaga, setVaga] = useState("");

  useEffect(() => {
    document.title = "Trabaja con Nosotros - Nutriz";
  }, []);

  function sendEmail(e) {
    e.preventDefault();

    const templateParams = {
      from_name: name,
      email: email,
      telefone: telefone,
      vaga: vaga,
    };

    emailjs
      .send(
        "service_1d99arb",
        "template_h802d7b",
        templateParams,
        "muoGg7jBcvD9hqCRI"
      )
      .then(
        (response) => {
          console.log("Email enviado", response.status, response.text);
          setName("");
          setEmail("");
          setTelefone("");
          setVaga("");
        },
        (err) => {
          console.log("Error: ", err);
        }
      );
    alert(
      "E-mail enviado con éxito! Gracias por contactarnos, te responderemos pronto."
    );
  }

  const handleTelefoneChange = (e) => {
    let input = e.target.value.replace(/\D/g, "");

    if (input.length > 2 && input.length <= 7) {
      input = `(${input.slice(0, 2)}) ${input.slice(2)}`;
    } else if (input.length > 7) {
      input = `(${input.slice(0, 2)}) ${input.slice(2, 7)}-${input.slice(7)}`;
    }

    setTelefone(input);
  };

  return (
    <main className={styles.main_qs}>
      <Header />

      <section className={styles.tc_s1}>
        <h1 className={styles.h1_trab_con}>Sé parte del equipo Nutriz</h1>
        <p className={styles.p_trab_con}>
          ¡Trabaja en una de las mayores empresas de alimentos de Brasil y con un equipo único! Llena el formulario y solicita una vacante.
        </p>
      </section>

      <section className={styles.tc_s2}>
        <form onSubmit={sendEmail}>
          <div className={styles.container_inputs}>
            <label htmlFor="name">Di tu nombre</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className={styles.container_inputs}>
            <label htmlFor="email">Tu e-mail</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={styles.container_inputs}>
            <label htmlFor="telefone">Tu teléfono</label>
            <input
              type="text"
              id="telefone"
              value={telefone}
              onChange={handleTelefoneChange}
              maxLength="15"
            />
          </div>
          <div className={styles.container_inputs}>
            <label htmlFor="vaga">Vacante</label>
            <input
              type="text"
              id="vaga"
              value={vaga}
              onChange={(e) => setVaga(e.target.value)}
            />
          </div>
          <div className={styles.container_checkbox}>
            <input type="checkbox" id="termos" required />
            <label htmlFor="termos">
              Acepto los términos de la{" "}
              <a href="/en/privacy-policy" target="_blank">
                Política de Privacidad y Procesamiento de Datos de Nutriz.
              </a>
            </label>
          </div>
          <button type="submit" className={styles.botao_tc}>
            ENVIAR
          </button>
        </form>
      </section>

      <Footer />
    </main>
  );
}

export default TrabalheConosco;
