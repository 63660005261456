import React, { useEffect } from "react";
import styles from "../../politicaDePrivacidade/PoliticaDePrivacidade.module.css";
import Header from "../../../components/espanhol/es_Header/es_header";
import Footer from "../../../components/espanhol/es_Footer/es_footer";

function PoliticaDePrivacidade() {
  useEffect(() => {
    document.title =
      "Política de Privacidad - Nutriz";
  }, []);

  return (
    <main className={styles.main_qs}>
      <Header />
      <section className={styles.section_priv}>
        <h1 className={styles.h1_politica_priv}>
          Términos de Privacidad, Políticas de Cookies y Tratamiento de Datos
        </h1>
        <p>
          Gracias por visitar el sitio web de Nutriz, empresa de alimentos,
          accesible a través del dominio nutriz.com.br. Valoramos su privacidad
          y nos esforzamos por proteger su información personal. Este documento
          describe cómo recopilamos, utilizamos y protegemos los datos que
          comparte con nosotros al acceder a nuestro sitio web. Le recomendamos
          que lea atentamente esta Política de Privacidad, Cookies y Tratamiento
          de datos para comprender cómo se tratará su información.
        </p>
        <h3>1. Recopilación de información</h3>
        <p>
          1.1 Información personal: Al visitar nuestro sitio web, es posible que
          le solicitemos que proporcione información personal, como nombre,
          dirección de correo electrónico, número de teléfono, currículum, para
          que podamos comunicarnos con usted o brindarle servicios
          personalizados. Esta información solo se recopilará con su
          consentimiento explícito y se utilizará de acuerdo con esta política.
        </p>
        <p>
          1.2 Información de navegación: Podemos recopilar información no
          personal, como dirección IP, tipo de navegador, proveedor de servicios
          de Internet (ISP), páginas visitadas, fecha y hora de acceso. Esta
          información se recopila automáticamente a través de cookies y otras
          tecnologías similares, y tiene como objetivo mejorar su experiencia de
          navegación en nuestro sitio web.
        </p>
        <h3>2. Uso de la información</h3>
        <p>
          2.1 Uso de Información Personal: La información personal que usted
          proporcione se utilizará para los siguientes fines:
        </p>
        <p>
          a) Proporcionarle servicios personalizados según sus preferencias;
        </p>
        <p>b) Tramitar y atender sus solicitudes, dudas o quejas;</p>
        <p>
          c) Enviar información relevante sobre nuestros productos, promociones
          o eventos, siempre que haya dado su consentimiento previo para recibir
          dichas comunicaciones;
        </p>
        <p>
          d) Mejorar continuamente nuestro sitio web y nuestros servicios, en
          función de sus opiniones y comentarios.
        </p>
        <p>
          2.2 Uso de la información de navegación: La información de navegación
          recopilada a través de cookies y tecnologías similares se utiliza con
          fines estadísticos y analíticos, para comprender mejor cómo nuestros
          visitantes interactúan con el sitio web y mejorar su usabilidad. Esta
          información puede compartirse de forma agregada y anónima con
          terceros, como socios publicitarios, para análisis de tendencias y
          fines de marketing.
        </p>

        <h3>3. Cookies</h3>
        <p>
          3.1 Cookies: Una cookie es un pequeño archivo de texto que se almacena
          en su dispositivo cuando visita nuestro sitio web. Utilizamos cookies
          para diversos fines, como reconocer su dispositivo, recordar sus
          preferencias y proporcionar contenido personalizado. Puede configurar
          su navegador para que rechace todas las cookies o para que le avise
          cuando se envíe una cookie. Sin embargo, tenga en cuenta que es
          posible que algunas partes del sitio web no funcionen correctamente si
          las cookies están deshabilitadas.
        </p>
        <h3>4. Intercambio de información</h3>
        <p>
          4.1 Compartir con terceros: Podemos compartir su información personal
          con terceros solo en los siguientes casos:
        </p>
        <p>a) Cuando usted nos autorice expresamente a ello;</p>
        <p>
          b) Para cumplir con obligaciones legales, como responder a solicitudes
          de autoridades competentes;
        </p>
        <p>
          c) Con proveedores de servicios externos que nos ayudan a operar el
          sitio web y brindar servicios, siempre que estos terceros acepten
          mantener su información confidencial.
        </p>
        <h3>5. Seguridad de datos</h3>
        <p>
          5.1 Seguridad: Implementamos medidas de seguridad adecuadas para
          proteger su información personal contra el acceso no autorizado, la
          alteración, la divulgación o la destrucción. Sin embargo, ningún
          método de transmisión o almacenamiento electrónico es 100% seguro, por
          lo que no podemos garantizar la seguridad absoluta de su información.
        </p>
        <h3>6. Cambios a esta Política</h3>
        <p>
          6.1 Actualizaciones: Podemos actualizar esta Política de Privacidad
          periódicamente para reflejar los cambios en nuestras prácticas de
          privacidad. Le recomendamos que revise esta política periódicamente
          para estar al tanto de cualquier cambio.
        </p>
        <h3>7. Contacto</h3>
        <p>
          7.1 Contacto: Si tiene alguna pregunta, inquietud o solicitud
          relacionada con esta Política de Privacidad, comuníquese con nosotros
          a través de los canales disponibles en nuestro sitio web.
        </p>
        <p>
          Al continuar utilizando nuestro sitio web, acepta los términos de esta
          Política de privacidad, cookies y procesamiento de datos.
        </p>
      </section>

      <Footer />
    </main>
  );
}

export default PoliticaDePrivacidade;
