import React, { useEffect } from "react";
import styles from "./quemSomos.module.css";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import qs_s2_img from "../../assets/img/qs_s2_img.png";
import qs_s3_img from "../../assets/img/qs_s3_img.png"; 

function QuemSomos() {
  useEffect(() => {
    document.title = "Institucional - Nutriz";

    document.addEventListener("scroll", function () {
      const scrollPosition = window.scrollY;
      const s2Img = document.getElementById("qs_s2_img");
      const s3Img = document.getElementById("qs_s3_img");

      // Ajusta a velocidade e direção do movimento
      const speed = 0.1;

      // Inverte o movimento das imagens
      s2Img.style.transform = `translateY(${-scrollPosition * speed}px)`;
      s3Img.style.transform = `translateY(${-scrollPosition * speed}px)`;
    });
  }, []);

  return (
    <main className={styles.main_qs}>
      <Header />
      <section className={styles.backgroundLavoura}>
        <h1 className={styles.h1_animation_qs}>
          Nutriz: uma história de excelência em alimentos congelados
        </h1>
        <a href="#qs_s2" className={styles.svg_seta}>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 32 32"
          >
            <path
              d="M16 23.333c-0.111 0-0.22-0.028-0.319-0.083l-14.667-8c-0.324-0.176-0.443-0.58-0.267-0.904 0.176-0.323 0.584-0.443 0.904-0.267l14.348 7.827 14.349-7.827c0.321-0.176 0.727-0.056 0.904 0.267 0.175 0.324 0.057 0.728-0.267 0.904l-14.667 8c-0.1 0.055-0.209 0.083-0.32 0.083zM16 18c-0.111 0-0.22-0.028-0.319-0.083l-14.667-8c-0.324-0.176-0.443-0.58-0.267-0.904 0.176-0.323 0.584-0.443 0.904-0.267l14.348 7.827 14.349-7.827c0.321-0.176 0.727-0.056 0.904 0.267 0.175 0.324 0.057 0.728-0.267 0.904l-14.667 8c-0.1 0.055-0.209 0.083-0.32 0.083z"
              fill="white"
            ></path>
          </svg>
        </a>
      </section>

      <section className={styles.qs_s2} id="qs_s2">
        <div className={styles.qs_s2_div1}>
          <p className={styles.qs_s2_titulo}>
            A escolha consciente para alimentos congelados e de qualidade
          </p>
          <p>
            Conquistamos presença no Brasil por meio de uma abordagem inovadora
            e com tecnologias avançadas que nos destacam no mercado.
          </p>
          <p>
            Garantimos a qualidade de nossos produtos o ano todo, desde a
            escolha das sementes até a entrega, com controle de resíduos e mão
            de obra responsável. Somos reconhecidos não apenas pela rapidez no
            processamento, mas também pelo frescor e qualidade.
          </p>
          <p>
            Com uma ampla gama de alimentos ultracongelados, livre de
            conservantes e em embalagens 100% recicláveis, proporcionamos
            praticidade, saúde e respeito ao meio ambiente, contando com uma
            seleção de insumos naturais para nutrição saudável que mantém o
            sabor e os nutrientes através de um processo de ultracongelamento de
            alta tecnologia.
          </p>
        </div>
        <img src={qs_s2_img} id="qs_s2_img" className={styles.qs_s2_img}></img>
        <img src={qs_s2_img} className={styles.qs_s2_img_mobile}></img>
      </section>

      <section className={styles.qs_s3}>
        <img src={qs_s3_img} id="qs_s3_img" className={styles.qs_s3_img}></img>
        <img src={qs_s3_img} className={styles.qs_s3_img_mobile}></img>
        <div className={styles.qs_s3_div1}>
          <p className={styles.qs_s3_titulo}>
            Uma trajetória de crescimento e inovação
          </p>
          <p>
            Desde a nossa fundação em 2003, nos estabelecemos como referência no
            mercado de alimentos congelados. Em 2008, inauguramos nossa própria
            câmara frigorífica, aprimorando a logística e conquistando a
            confiança de nossos clientes. Em 2011, lançamos uma linha de
            produtos atacadistas, seguida pela reformulação visual de todas as
            embalagens em 2012.
          </p>
          <p>
            Ao longo do tempo, criamos as marcas BOM APETITE e MACERATA,
            destinadas a auxiliar a Nutriz a ter uma atuação estratégica no
            mercado, sendo utilizadas para a comercialização de alguns produtos
            da linha Nutriz e também para a distribuição de alguns alimentos em
            determinadas regiões do Brasil.
          </p>
          <p>
            Assim, desde o nosso início, continuamos evoluindo, expandindo e,
            cada vez mais oferecendo qualidade e variedade para todos os nossos
            clientes.
          </p>
        </div>
      </section>

      <svg
        aria-hidden="true"
        fill="#df231d"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 50"
        preserveAspectRatio="none"
      >
        <path d="M0 40c0 0 200-25 500-25s500 25 500 25V0H0v50z"></path>
      </svg>

      <section className={styles.qs_s4}>
        <div className={styles.div_qs_s4}>
          <h3>Missão</h3>
          <p>
            Produzir, comercializar e distribuir com eficiência e alta
            tecnologia alimentos seguros, saudáveis e saborosos
          </p>
        </div>
        <div className={styles.div_qs_s4}>
          <h3>Visão</h3>
          <p>
            Ser uma referência em alimentos e vegetais congelados e tornar a
            nível Brasil as marcas da Nutriz um sinônimo de produtos de
            qualidade
          </p>
        </div>
        <div className={styles.div_qs_s4}>
          <h3>Valores</h3>
          <p>
            Ética, reputação, transparência, responsabilidade social e respeito
            ao meio ambiente
          </p>
        </div>
        <div className={styles.div_qs_s4}>
          <h3>Filosofia</h3>
          <p>
            Disciplina, comprometimento, trabalho em equipe, inovação continua e
            respeito absoluto às legislações, clientes, colaboradores,
            fornecedores e parceiros
          </p>
        </div>
        <div className={styles.div_qs_s4}>
          <h3>Objetivos</h3>
          <p>Eficiência operacional e comercial, lucro e crescimento</p>
        </div>
      </section>

      <section className={styles.qs_s5}>
        <h2 className={styles.qs_s5}>
          Política Integrada da
          <span>
            <span className={styles.span_sub_qs_s5}>Qualidade e Segurança</span>
            <svg
              className={styles.svg_s5}
              viewBox="-400 -55 730 60"
              preserveAspectRatio="none"
            >
              <path
                d="m -383.25 -6 c 55.25 -22 130.75 -33.5 293.25 -38 c 54.5 -0.5 195 -2.5 401 15"
                stroke="#df231d"
                pathLength="1"
                strokeWidth="14"
                fill="none"
              ></path>
            </svg>
          </span>
          de Alimentos
        </h2>

        <p>
          A Nutriz Indústria e Comércio de Alimentos Ltda, em suas atividades de
          produção de vegetais congelados, atua através do comprometimento,
          envolvimento e desenvolvimento dos seus colaboradores para a melhoria
          contínua do processo de gestão da qualidade e segurança de alimentos.
          Através de seus diversos canais de comunicação, assegura um bom
          relacionamento com suas partes interessadas. Mantém a eficácia e
          eficiência dos processos produtivos de modo a superar as expectativas
          dos consumidores e atender aos requisitos legais e de clientes,
          maximizando assim o valor da empresa.
        </p>
      </section>

      <section className={styles.qs_s6}>
        <h2>
          Alimentos de qualidade, garantia de satisfação dos clientes e
          responsabilidade socioambiental
        </h2>
        <p>
          Buscamos a satisfação dos clientes com a entrega de produtos
          saudáveis, nos empenhando sempre no aprimoramento dos processos,
          buscando constantemente integrar fornecedores a fim de promover
          crescimento humano. Percorremos todas as etapas com respeito ao meio
          ambiente, contribuindo para o desenvolvimento e o progresso social e
          econômico do Brasil com alimentos de qualidade.
        </p>
      </section>

      <section className={styles.qs_s7}>
        <h2>
          Experimente agora e descubra a qualidade e sabor dos produtos Nutriz!
        </h2>
        <a href="/produtos" className={styles.link_conheca_prod}>
          <span className={`${styles.seta} bi bi-arrow-right`}></span>
          <span className={`${styles.seta2} bi bi-dash-lg`}></span>
          Conheça nossos produtos
        </a>
      </section>

      <Footer />
    </main>
  );
}

export default QuemSomos;
