import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import styles from "./home.module.css";
import imagem_brocolis_secao2 from "../../assets/img/imagem_brocolis_secao2.png";
import banner_s5_1 from "../../assets/img/banner_s5_1.png";
import banner_s5_2 from "../../assets/img/banner_s5_2.png";
import banner_s5_3 from "../../assets/img/banner_s5_3.png";
import banner_s5_4 from "../../assets/img/banner_s5_4.png";
import BackgroundVideo from "../../components/video/video";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import Popup from "../../components/popup/popup";

function Home() {
  let nav = "1"
  if (window.innerWidth < 500) {
    nav = "2";
  }
  
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPopupOpen(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    AOS.init({ duration: 1000 });
    document.title = `Nutriz Alimentos Congelados`;

    const hash = window.location.hash;
    if (hash === "#secao6") {
      window.location.href = "https://nutriz.com.br/#secao6";
    }

    // efeito de rolagem da imagem
    const handleScroll = () => {
      const section = document.querySelector(`.${styles.secao4}`);
      if (section) {
        const scrollPosition = window.pageYOffset;
        const windowHeight = window.innerHeight;
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        if (
          scrollPosition + windowHeight > sectionTop &&
          scrollPosition < sectionTop + sectionHeight
        ) {
          const offset =
            (scrollPosition + windowHeight - sectionTop) /
            (3 * (windowHeight + sectionHeight));

          section.style.setProperty("--bg-position", `${80 - offset * 80}%`);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <main className={styles.main_home}>
      <Header />
      <BackgroundVideo web={nav}/>
      {/* {isPopupOpen && <Popup handleClose={closePopup} />} */}

      <section className={styles.secao2}>
        <div className={styles.div_textos_s2}>
          <p
            className={styles.titulo_s2}
            data-aos="fade-up"
            data-aos-duration="500"
          >
            Alimentos Congelados
          </p>
          <p
            className={styles.titulo_s2}
            data-aos="fade-up"
            data-aos-duration="750"
          >
            Nutriz: Sabor e frescor
          </p>
          <p
            className={styles.titulo_s2}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            na sua mesa
          </p>

          <p>
            Estamos presentes em todo o território brasileiro, adotando uma
            abordagem inovadora e utilizando equipamentos modernos para garantir
            qualidade em todas as etapas da nossa produção, desde a escolha da
            semente até a entrega ao cliente.
          </p>
          <p>
            Nossos produtos são ecologicamente corretos, mantendo as
            características nutricionais, sabor e textura dos alimentos.
          </p>
          <p>
            Somos referência no setor de congelados e nos destacamos pelo
            comprometimento e seriedade.
          </p>
          <a href="/institucional" className={styles.botao_saibamais_s2}>
            SAIBA MAIS
          </a>
        </div>
        <img src={imagem_brocolis_secao2}></img>
      </section>

      <section className={styles.secao3}>
        <h1>Conheça os principais diferenciais dos alimentos Nutriz:</h1>
        <div className={styles.div_s3}>
          <div className={styles.div1_s3}>
            <p className={styles.titulo_div1_s3}>
              Alimentos 100% naturais, livres de aditivos e conservantes
            </p>
            <p className={styles.p_div1_s3}>
              Selecionados, limpos, descascados, cortados e congelados
              individualmente. Sem corantes ou conservantes, preservando a
              autenticidade e qualidade.
            </p>
          </div>
          <div className={styles.div2_s3}>
            <p className={styles.titulo_div2_s3}>Praticidade e economia</p>
            <p className={styles.p_div2_s3}>
              Sem a necessidade de descongelar antes do preparo e com a
              possibilidade de retornar o restante da embalagem ao freezer,
              evitando desperdícios!
            </p>
          </div>
          <div className={styles.div3_s3}>
            <p className={styles.titulo_div3_s3}>
              Preparo rápido e com qualidade garantida
            </p>
            <p className={styles.p_div3_s3}>
              Nossos alimentos oferecem um rápido preparo, garantindo qualidade
              nutricional e microbiológica.
            </p>
          </div>
        </div>
      </section>

      <section className={styles.secao4}>
        <p>
          Os
          <span className={styles.span_s4}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              focusable="false"
              viewBox="0 0 12 12"
            >
              <path stroke="currentColor" strokeLinecap="round" d="M3 6.5h6" />
            </svg>
            melhores
          </span>
          alimentos congelados!
        </p>
      </section>

      <section className={styles.secao5}>
        <a href="/produtos" data-aos="fade-left" data-aos-delay="50">
          <div className={styles.img_container}>
            <img src={banner_s5_1} alt="Linha Institucional" />
          </div>
          <p>Linha</p>
          <p className={styles.s2_descr}>Institucional</p>
        </a>
        <a href="/produtos" data-aos="fade-left" data-aos-delay="150">
          <div className={styles.img_container}>
            <img src={banner_s5_2} alt="Linha Atacado" />
          </div>
          <p>Linha</p>
          <p className={styles.s2_descr}>Atacado</p>
        </a>
        <a href="/produtos" data-aos="fade-left" data-aos-delay="250">
          <div className={styles.img_container}>
            <img src={banner_s5_3} alt="Linha Varejo" />
          </div>
          <p>Linha</p>
          <p className={styles.s2_descr}>Varejo</p>
        </a>
        <a href="/produtos" data-aos="fade-left" data-aos-delay="350">
          <div className={styles.img_container}>
            <img src={banner_s5_4} alt="Linha Bom Apetite" />
          </div>
          <p>Linha</p>
          <p id="secao6" className={styles.s2_descr}>Bom Apetite</p>
        </a>
      </section>

      <section className={styles.secao6}>
        <p data-aos="fade-up" data-aos-delay="30">
          Inspire-se com <br />
          <span className={styles.span_receitas}>
            <svg
              className={styles.svg_receitas}
              viewBox="-400 -55 730 60"
              preserveAspectRatio="none"
            >
              <path
                d="m -383.25 -6 c 55.25 -22 130.75 -33.5 293.25 -38 c 54.5 -0.5 195 -2.5 401 15"
                stroke="#007943"
                pathLength="1"
                strokeWidth="20"
                fill="none"
              ></path>
            </svg>
            receitas deliciosas
          </span>{" "}
          para preparar com os seus alimentos Nutriz!
        </p>
        <a href="https://www.instagram.com/stories/highlights/18240165124258007">CONFIRA!</a>
      </section>

      <Footer />
    </main>
  );
}

export default Home;
